import React from 'react';
import Navbar from 'components/publicRoute/navbar';
import CookieConcent from 'components/publicRoute/cookieConcent';
import DocumentMeta from 'react-document-meta';
import { meta } from 'constants/meta';
import Backdrop from 'components/backdrop';

const Index = ({ children, pathname }) => {
  return (
    <div id="container" className="">
      <Backdrop />
      <DocumentMeta {...meta[pathname || '/']} />
      <Navbar />
      <div
        data-background="light"
        className="w-full flex items-center pt-12 sm:pt-20 justify-center bg-grey-50"
      >
        {children}
        <CookieConcent isShown={true} />
      </div>
    </div>
  );
};

export default Index;
