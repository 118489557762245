import React, { useState, useEffect } from 'react';
import SideMenu from './sideMenu';
import { navBar } from './navBarLinks';
import Logo from './logo';
import { FiMenu } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import LoginDemo from 'components/buttons/loginDemo';

const Navbar = () => {
  const [isSideOpen, setIsSideOpen] = useState(false);

  const [scrollOnTop, setScrollOnTop] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 80) {
        setScrollOnTop(false);
      } else {
        setScrollOnTop(true);
      }
    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => window.removeEventListener('scroll', handleScroll);
  }, [scrollOnTop]);

  return (
    <>
      <div className="fixed w-full z-30">
        <div
          className={`bg-white text-default transition duration-500 ${
            scrollOnTop ? 'opacity-100 py-3' : 'opacity-90 py-1'
          } ease-in-out w-full flex items-center justify-between lg:justify-around px-2 sm:px-4 shadow-xl`}
        >
          <div className="">
            <Logo scrollOnTop={scrollOnTop} />
          </div>
          <div className="hidden lg:flex lg:flex-row items-center whitespace-no-wrap">
            <LoginDemo>
              <div className="btn btn-default w-full bg-mila-700 hover:bg-mila-900 cursor-pointer text-white flex justify-center items-center btn-rounded text-sm hpRegFont">
                Demo
              </div>
            </LoginDemo>
            {navBar.map((item, key) => {
              return (
                <Link key={key} to={item.link} className={item.classOfLink}>
                  <span className="text-base">{item.title}</span>
                </Link>
              );
            })}
          </div>
          <div className="lg:hidden flex space-x-3 items-center">
            <LoginDemo>
              <div className="btn btn-default w-full bg-mila-700 hover:bg-mila-900 cursor-pointer text-white flex justify-center items-center btn-rounded text-sm hpRegFont">
                Demo
              </div>
            </LoginDemo>
            <Link to="/create-account">
              <div className="hpRegFont text-sm px-4 py-1 btn-outlined bg-transparent text-mila-300 border rounded border-mila-300 hover:text-mila-300 hover:bg-grey-100">
                Sign Up
              </div>
            </Link>
            <span
              onClick={() => setIsSideOpen(true)}
              className="cursor-pointer"
            >
              <FiMenu size={35} />
            </span>
          </div>
        </div>
      </div>
      {isSideOpen && (
        <span className="lg:hidden">
          <SideMenu setIsSideOpen={setIsSideOpen} />
        </span>
      )}
    </>
  );
};

export default Navbar;
