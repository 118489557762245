import React from 'react';
import { H1, P } from '../../../../components';
import PAGE from '../../../../components/page';
import { SHARPIMAGE } from '../../../../components/images';
import exchange from './exchange.png';

const Index = () => {
  return (
    <PAGE>
      <H1>EXCHANGE</H1>
      <P>
        MilaCoins allows an easy and fast conversion from crypto to fiat and
        crypto to crypto. To make the exchange, first of all you need to specify
        the amount of money you wish to exchange. To exchange the minimum
        amount, you can press MIN below the amount field. If there is a need to
        exchange the entire amount on your wallet balance, the MAX button below
        the amount field can be used for this purpose.{' '}
      </P>

      <P>
        When you choose the fiat / crypto wallet for the exchange transaction
        from the drop-down menu, you will be able to view the equivalent amount
        in the currency you intend to exchange the funds to. Please note that
        the current exchange rate in EUR will be fixed for 10 seconds to
        mitigate volatility risks. Before clicking EXCHANGE, double-check the
        entered details.
      </P>
      <SHARPIMAGE>{exchange}</SHARPIMAGE>
    </PAGE>
  );
};

export default Index;
