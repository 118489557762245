import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import LeftSidebar from 'pages/documentation/LeftMenu';
import Navbar from 'pages/documentation/navbar';
import Navigator from 'pages/documentation/navigator';
import { navigatorArr } from 'pages/documentation/navigation';
import Hotkeys from 'components/hotkeys';
import Backdrop from 'components/backdrop';
import { NotificationContainer } from 'react-notifications';
import { CSSTransition } from 'react-transition-group';
import { useRouter } from 'myHooks';
import Footer from 'pages/public/homepage/footer';
import { meta } from 'constants/meta';
import DocumentMeta from 'react-document-meta';
import 'css/layouts/layout-1.css';
import CookieConcent from 'components/publicRoute/cookieConcent';

const Documentation = ({ children }) => {
  let { pathname } = useRouter();
  const navigator = navigatorArr;
  const { config } = useSelector(
    state => ({
      config: state.config
    }),
    shallowEqual
  );
  const { layout, collapsed } = { ...config };
  let { background, navbar, logo, leftSidebar, rightSidebar } = {
    background: 'documentation',
    leftSidebar: 'light',
    navbar: 'light',
    rightSidebar: 'light'
  };
  let key = `${[
    layout,
    collapsed,
    background,
    navbar,
    logo,
    leftSidebar,
    rightSidebar
  ].join('-')}`;

  const getIndex = () => {
    if (pathname === '/documentation') return -1;
    if (pathname === '/documentation/introduction') return 0;
    return navigator.findIndex(item => item.url === pathname);
  };

  const setPrev = () => {
    if (getIndex() === -1) return 'noBack';
    if (getIndex() === 0) return 'noBack';
    return {
      title: navigator[getIndex() - 1].title,
      url: navigator[getIndex() - 1].url
    };
  };

  const setNext = () => {
    if (getIndex() === -1) return 'noNext';
    if (getIndex() === navigator.length - 1) return 'noNext';
    return {
      title: navigator[getIndex() + 1].title,
      url: navigator[getIndex() + 1].url
    };
  };

  return (
    <div
      data-layout={layout}
      data-collapsed={collapsed}
      data-background={background}
      data-navbar={navbar}
      data-logo={logo}
      data-left-sidebar={leftSidebar}
      className={`${background === 'dark' ? 'dark-mode' : 'default-mode'}`}
    >
      <Backdrop />
      <DocumentMeta {...meta[pathname || '/documentation']} />
      <NotificationContainer />
      <Hotkeys key={key} />
      <CSSTransition in={collapsed} timeout={300} classNames="collapse-layout">
        <div className="wrapper">
          <div className="md:hidden">
            <LeftSidebar isPreLander={pathname === '/documentation'} />
          </div>
          <div className="hidden md:block">
            {pathname !== '/documentation' && <LeftSidebar />}
          </div>
          <div className={`main w-full`}>
            <Navbar isPreLender={pathname === '/documentation'} />
            <div
              className={`min-h-screen w-full ${
                pathname === '/documentation' ? `` : `w-full  sm:flex   pt-10`
              }`}
            >
              <div
                className={`flex flex-col ${
                  pathname === '/documentation' ? `w-full` : `w-full pt-10`
                }`}
              >
                <div
                  className={pathname !== '/documentation' && 'sm:flex h-full'}
                >
                  {children}
                </div>
                <div className="w-full md:w-10/12 xl:w-7/12 pb-10">
                  <Navigator next={setNext()} prev={setPrev()} />
                </div>
              </div>
              <div
                className={`w-0 ${
                  pathname !== '/documentation'
                    ? 'w-0 xl:w-1/3 bg-mila-lightBg border-l border-grey-card h-full'
                    : ''
                }`}
              ></div>
            </div>
          </div>
        </div>
      </CSSTransition>
      <CookieConcent isShown={true} />
      <Footer />
    </div>
  );
};
export default Documentation;
