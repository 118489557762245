import React from 'react';
import { H1, P } from '../../../../components';
import PAGE from '../../../../components/page';
import { SHARPIMAGE } from '../../../../components/images';
import receive from './receive.png';

const Index = () => {
  return (
    <PAGE>
      <H1>RECEIVE</H1>
      <P>
        MilaCoins allows you to easily receive funds to your crypto wallets.
      </P>
      <SHARPIMAGE>{receive}</SHARPIMAGE>
      <P>
        To get a crypto address for receiving payments into your MilaCoins
        wallet, press COPY next to the address field or scan the QR code for
        that address. You can share it with your customer who wants to make
        crypto payments for your goods or services. To generate a new crypto
        address for the next payment, press the button with the renewal icon
        next to the address field.
      </P>
    </PAGE>
  );
};

export default Index;
