import React from 'react';
import { H1, P, H4, UL, LI, BR } from '../../../components';
import PAGE from '../../../components/page';
import { SHARPIMAGE } from '../../../components/images';
import tFA from './2FA.png';
import ChangePassword from './ChangePassword.png';
import DeviceList from './DeviceList.png';

const Index = () => {
  return (
    <PAGE>
      <H1>Security Setup</H1>

      <H4>Two Factor Authentication</H4>
      <P>
        You can enable Two Factor Authentication with SMS to ensure the security
        of your account.
      </P>
      <P>To start using 2FA please click "ON"</P>
      <SHARPIMAGE>{tFA}</SHARPIMAGE>
      <UL>
        <LI>Enter a valid phone number.</LI>
        <LI>
          Click the button "SEND CODE" and enter the received code in the field
          "SMS Verification Code".{' '}
        </LI>
        <LI>
          Click "SEND CODE" for the email verification and enter the code you
          get to your email in the "Email Verification Code" field.
        </LI>
        <LI>
          Click "ENABLED SMS" to complete the activation of 2FA in your
          MilaCoins account.
        </LI>
      </UL>

      <BR />

      <H4>Change Password</H4>
      <P>
        You can also change the account password through the "Security" tab.
      </P>
      <SHARPIMAGE>{ChangePassword}</SHARPIMAGE>
      <BR />
      <H4>Device List</H4>
      <P>
        When the system identifies a new device that is trying to log in to your
        account, a message will pop up, asking you whether you want to add this
        device to your account.
      </P>
      <P>
        If the 2FA is off, you'll receive a code through your email. if the 2FA
        is on, you'll get two codes (via email and SMS).
      </P>
      <P>
        In the "Security tab", you'll see the "Device List". 
        You can ONLY disable devices, but not enable new ones.
      </P>
      <SHARPIMAGE>{DeviceList}</SHARPIMAGE>
    </PAGE>
  );
};

export default Index;
