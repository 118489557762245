import { useCallback, useEffect } from 'react';

const useActive = onActive => {
  const handleOnActive = useCallback(() => onActive && onActive(), [onActive]);

  useEffect(() => {
    window.addEventListener('mousemove', handleOnActive);
    window.addEventListener('keydown', handleOnActive);
    window.addEventListener('ontouchend', handleOnActive);

    return () => {
      window.removeEventListener('mousemove', handleOnActive);
      window.removeEventListener('keydown', handleOnActive);
      window.removeEventListener('ontouchend', handleOnActive);
    };
  }, [handleOnActive]);

  return null;
};

export default useActive;
