import React from 'react';

export const SHARPIMAGE = ({
  children,
  height = 'auto',
  width = 'auto',
  description
}) => {
  return (
    <div className="pt-2 m-10">
      <img
        className={`h-${height} w-${width} p-2 border border-grey-500 border-opacity-50 rounded`}
        src={children}
        alt="docImg"
      />
      {description}
    </div>
  );
};

export const ROUNDIMAGE = ({ children, size = 'auto' }) => {
  return (
    <div className="pt-2">
      <img
        className={`rounded-full h-${size} w-${size}`}
        src={children}
        alt="docImg"
      />
    </div>
  );
};

export const ROUNDCORNERSIMAGE = ({
  children,
  height = 'auto',
  width = 'auto'
}) => {
  return (
    <div className="pt-2">
      <img
        className={`rounded-lg h-${height} w-${width}`}
        src={children}
        alt="docImg"
      />
    </div>
  );
};

export const PANORAMICIMAGE = ({ children, height = 'auto' }) => {
  return (
    <div className="pt-2">
      <img className={`h-${height} w-full`} src={children} alt="docImg" />
    </div>
  );
};
