exports.meta = {
  '/': {
    title: 'MilaCoins | Accept Crypto Without Risk',
    description:
      'MilaCoins widget enables businesses to accept crypto payments on your website and connect millions of crypto users. Convert your crypto to a FIAT currency wallet of your choice.',
    canonical: 'https://milacoins.com/',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'react,meta,document,html,tags'
      }
    }
  },
  '/get-started': {
    title:
      'MilaCoins | How To Get Started | Take Your Business To The Next Level ',
    description:
      'Accept And Manage Crypto Payments From Your Customers And Choose The Way You Wish To Settle. The System Instantly Converts Incoming Crypto Payment To Your Preferred Currency Eliminating Risk.',
    canonical: 'https://milacoins.com/get-started',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'react,meta,document,html,tags'
      }
    }
  },
  '/why-us': {
    title: 'MilaCoins | Why MilaCoins | Best Merchant Solution ',
    description:
      'MilaCoins Is A Crypto Payment Processor Designed To Simplify The Complexities Of Payment Processing. It Enables Businesses, Merchants, E-Commerce Owners And Organizations To Add Crypto Payments To Their Payment Operations.With MilaCoins You Will Get Instant Payments Straight To Your Wallet, Quick Conversions, Permission-Based Account Management, Fiat Payouts Straight To Your Bank Account, Quick Invoicing Feature, Superior Customer Privacy, System Security, Free Support.',
    canonical: 'https://milacoins.com/why-us',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'react,meta,document,html,tags'
      }
    }
  },
  '/FAQ': {
    title:
      'MilaCoins | MilaCoins Help Center | Questions And Answers From MilaCoins | Everything You Need To Know About MilaCoins Payment Gateway',
    description:
      'Learn How MilaCoins Works, How You Can Manage Your Wallet Operations, How You Can Receive, Send, Exchange, And Withdraw Crypto Payments, Etc. Get Answers To All Kinds Of Questions About Our Crypto Payment Gateway, From The Basic To The Specific.',
    canonical: 'https://milacoins.com/FAQ',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'react,meta,document,html,tags'
      }
    }
  },
  '/contact-us': {
    title:
      'MilaCoins | Contact Us | Have A Question Or Want To Get In Touch With MilaCoins',
    description:
      'With MilaCoins, all types of business can make cross-border payments with Bitcoin and other cryptocurrencies. Call or email today to get in touch with our support team.',
    canonical: 'https://milacoins.com/contact-us',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'react,meta,document,html,tags'
      }
    }
  },
  '/login': {
    title: 'MilaCoins | Login',
    canonical: 'https://milacoins.com/login',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'react,meta,document,html,tags'
      }
    }
  },
  '/create-account': {
    title: 'MilaCoins | Sign Up | Start Accepting And Disbursing Payments',
    description:
      'Are you a freelancer, online seller, or marketplace? You can make crypto payments instantly and securely with MilaCoins.',
    canonical: 'https://milacoins.com/create-account',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'react,meta,document,html,tags'
      }
    }
  },
  '/logout': {
    title: 'MilaCoins | Logout',
    canonical: 'https://milacoins.com/logout',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'react,meta,document,html,tags'
      }
    }
  },
  '/forgot-password': {
    title: 'MilaCoins | Forgot Password',
    canonical: 'https://milacoins.com/forgot-password',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'react,meta,document,html,tags'
      }
    }
  },
  '/pricing': {
    title: 'MilaCoins | Pricing | Automated Crypto Payment Solutions',
    description: '1.0% for each successful transaction.',
    canonical: 'https://milacoins.com/pricing',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'react,meta,document,html,tags'
      }
    }
  },
  '/eCommerce': {
    title: 'MilaCoins | eCommerce | Accept Payments Across Borders',
    description: `Enable Crypto Transactions On Your Website To Attract New Customers. Take Advantage Of MilaCoins' Global Payment Gateway To Facilitate Cross-Border Transactions.`,
    canonical: 'https://milacoins.com/eCommerce',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'react,meta,document,html,tags'
      }
    }
  },
  '/professionalsServices': {
    title:
      'MilaCoins | Professional Services | A Great Place To Accept Crypto Payments From Global Clients',
    description:
      'Increase Your Income. Get The Benefit From Fast, Secure And Cost-Savvy Crypto Payment Gateway MilaCoins.',
    canonical: 'https://milacoins.com/',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'react,meta,document,html,tags'
      }
    }
  },
  '/ITDevelopers': {
    title: 'MilaCoins | IT Developers | Easy Integrations With MilaCoins',
    description:
      'Start Using Crypto Payments Solutions Provided By MilaCoins. Integrate MilaCoins Gateway To Any Webapp.',
    canonical: 'https://milacoins.com/ITDevelopers',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'react,meta,document,html,tags'
      }
    }
  },
  '/charities': {
    title:
      'MilaCoins | Charities | Accept Donations In Crypto From Anyone. Increase Donor Engagement',
    description:
      'It will beeasier for you to raise moneywhen you use MilaCoins crypto payment gateway.',
    canonical: 'https://milacoins.com/charities',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'react,meta,document,html,tags'
      }
    }
  },
  '/merchants': {
    title:
      'MilaCoins | Merchants | Avoid Extra Complexities. Use Crypto For Your Payment Operations',
    description:
      'Explore the Platform that will Allow you toDesign, Develop, and Manage Crypto Paymentsexactly in the way you want.',
    canonical: 'https://milacoins.com/',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'react,meta,document,html,tags'
      }
    }
  },
  '/privacy-policy': {
    title: 'MilaCoins | Privacy Policy',
    description:
      'We at MilaCoins are committed to protecting your privacy. It is our commitment to protect our partners and our commitment to maintain a high level of privacy and security.',
    canonical: 'https://milacoins.com/privacy-policy',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'react,meta,document,html,tags'
      }
    }
  },
  '/terms-of-service': {
    title: 'MilaCoins | Merchant Terms of Use',
    description: `Read about MilaCoins's Legal Agreements. For information on our policies & agreements, please visit our website.`,
    canonical: 'https://milacoins.com/terms-of-service',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'react,meta,document,html,tags'
      }
    }
  },
  '/aml-policy': {
    title: 'MilaCoins | AML-Policy',
    description: `The Company’s Anti-Money Laundering (“AML”) Policy and Identity Verification Policy (collectively the “AML Policy”) is designed to articulate its commitment to detecting.`,
    canonical: 'https://milacoins.com/aml-policy',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'react,meta,document,html,tags'
      }
    }
  },
  '/documentation/Introduction': {
    title: 'MilaCoins | Introduction',
    description: `Welcome to MilaCoins! Our payment platform has been designed to provide a user-friendly, cheap and secure gateway to accept cryptocurrency payments. It can be used by companies of different sizes for automating the process of accepting cryptocurrencies payments from customers and making currency exchange transactions.`,
    canonical: 'https://milacoins.com/documentation/Introduction',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'react,meta,document,html,tags'
      }
    }
  },
  '/documentation/GettingStarted/Environments': {
    title: 'MilaCoins | Environments',
    description: `In addition to the Production environment, MilaCoins also provides developers with the Sandbox test environment.`,
    canonical:
      'https://milacoins.com/documentation/GettingStarted/Environments',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'react,meta,document,html,tags'
      }
    }
  },
  '/documentation/GettingStarted/CreatingAccount': {
    title: 'MilaCoins | Create Account',
    description: `The first account you set up will be a root account by default. It means that you’ll be the superuser or the administrator of the account.`,
    canonical:
      'https://milacoins.com/documentation/GettingStarted/CreatingAccount',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'react,meta,document,html,tags'
      }
    }
  },
  '/documentation/GettingStarted/CompanySetup': {
    title: 'MilaCoins | Company Setup',
    description: `The company tab will bring you to the setup of your bank account and agents of your company (Users).`,
    canonical:
      'https://milacoins.com/documentation/GettingStarted/CompanySetup',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'react,meta,document,html,tags'
      }
    }
  },
  '/documentation/GettingStarted/WalletSetup': {
    title: 'MilaCoins | Wallet Setup',
    description: `MilaCoins has a wallet that allows you to receive, send, and exchange funds. All our users can safely store their funds in a safely audited cryptocurrency wallet system. By utilizing the MilaCoins wallet, you can receive payment in cryptocurrency. MilaCoins wallet also allows you to exchange money and send payments to other MilaCoins wallets.`,
    canonical: 'https://milacoins.com/documentation/GettingStarted/WalletSetup',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'react,meta,document,html,tags'
      }
    }
  },
  '/documentation/GettingStarted/ProjectSetup': {
    title: 'MilaCoins | Project Setup',
    description: `When signing to your account, the default project will be the one that is shown. You can see the project name on the top left corner.`,
    canonical:
      'https://milacoins.com/documentation/GettingStarted/ProjectSetup',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'react,meta,document,html,tags'
      }
    }
  },
  '/documentation/GettingStarted/SecuritySetup': {
    title: 'MilaCoins | Security Setup',
    description: `You can enable Two Factor Authentication with SMS to ensure the security of your account. You can also change the account password through the "Security" tab. When the system identifies a new device that is trying to log in to your account, a message will pop up, asking you whether you want to add this device to your account.`,
    canonical:
      'https://milacoins.com/documentation/GettingStarted/SecuritySetup',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'react,meta,document,html,tags'
      }
    }
  },
  '/documentation/GettingStarted/ProfileSetup': {
    title: 'MilaCoins | Profile Setup',
    description: `Go to the Profile Tab of your account settings. Upload Image, enter your Name, and specify Main Project or Theme. Please note that you can change your profile settings any time.`,
    canonical:
      'https://milacoins.com/documentation/GettingStarted/ProfileSetup',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'react,meta,document,html,tags'
      }
    }
  },
  '/documentation/Wallet': {
    title: 'MilaCoins | Wallet',
    description: `MilaCoins wallets allow our users to receive payments from their customers. Also, they can use these wallets to send, exchange, and store the received funds.`,
    canonical: 'https://milacoins.com/documentation/Wallet',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'react,meta,document,html,tags'
      }
    }
  },
  '/documentation/Wallet/SettingUpWallets': {
    title: 'MilaCoins | Setting Up Wallets',
    description: `MilaCoins allows you to accept, store, send, and exchange funds in a properly secured cryptocurrency wallet system. Once you have registered your account, you can set up your wallets. When you create a new account with MilaCoins, four wallets will be automatically added by default: BTC, BCH, ETH, USDT USDC and USDP. You can start sending, receiving, and exchanging money from any of these wallets. If you want to create a wallet for some other currency, click the “ADD WALLET” button.`,
    canonical:
      'https://www.milacoins.com/documentation/Wallet/SettingUpWallets',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'react,meta,document,html,tags'
      }
    }
  },
  '/documentation/Wallet/WalletManagement': {
    title: 'MilaCoins | Wallet Management',
    description: `MilaCoins Wallet allows you to manage your crypto assets directly on the platform. MilaCoins allows you to open a separate wallet for each supported currency. You can get settled directly to your bank account.`,
    canonical:
      'https://www.milacoins.com/documentation/Wallet/WalletManagement',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'react,meta,document,html,tags'
      }
    }
  },
  '/documentation/Wallet/SupportedWallets': {
    title: 'MilaCoins | Supported Wallets',
    description: `Supported Wallets: BTC, BCH. ETH, USDT, USDP, USDC`,
    canonical:
      'https://www.milacoins.com/documentation/Wallet/WalletManagement',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'react,meta,document,html,tags'
      }
    }
  },
  '/documentation/Wallet/WalletTypes': {
    title: 'MilaCoins | Wallet Types',
    description: `There are two types of internal wallets on the MilaCoins platform: crypto wallets,fiat wallets.`,
    canonical: 'https://www.milacoins.com/documentation/Wallet/WalletTypes',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'react,meta,document,html,tags'
      }
    }
  },
  '/documentation/Wallet/WalletTypes/receive': {
    title: 'MilaCoins | Wallet | Receive',
    description: `MilaCoins allows you to easily receive funds to your crypto wallets.`,
    canonical:
      'https://www.milacoins.com/documentation/Wallet/WalletTypes/receive',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'react,meta,document,html,tags'
      }
    }
  },
  '/documentation/Wallet/WalletTypes/send': {
    title: 'MilaCoins | Wallet | Send',
    description: `You can send funds via crypto wallets only. To initiate a crypto payment, enter the amount of money you want to send (unlimited). You will be asked to choose the currency of the transaction and then copy & paste the address of the external crypto wallet you wish to send the funds to. All wallet types are supported. Before clicking SEND, double-check the entered details.`,
    canonical:
      'https://www.milacoins.com/documentation/Wallet/WalletTypes/send',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'react,meta,document,html,tags'
      }
    }
  },
  '/documentation/Wallet/WalletTypes/exchange': {
    title: 'MilaCoins | Wallet | Exchange',
    description: `MilaCoins allows an easy and fast conversion from crypto to fiat and crypto to crypto. To make the exchange, first of all you need to specify the amount of money you wish to exchange. To exchange the minimum amount, you can press MIN below the amount field. If there is a need to exchange the entire amount on your wallet balance, the MAX button below the amount field can be used for this purpose.`,
    canonical:
      'https://www.milacoins.com/documentation/Wallet/WalletTypes/exchange',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'react,meta,document,html,tags'
      }
    }
  },
  '/documentation/Wallet/WalletTypes/withdraw': {
    title: 'MilaCoins | Wallet | Withdraw',
    description: `MilaCoins is a crypto-to-fiat service, which means that you can link your bank account directly to your MilaCoins account. Funds can only be withdrawn from fiat wallets. To withdraw funds from your crypto wallet, you will need to convert them to fiat first.`,
    canonical:
      'https://www.milacoins.com/documentation/Wallet/WalletTypes/withdraw',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'react,meta,document,html,tags'
      }
    }
  },
  '/documentation/Invoicing': {
    title: 'MilaCoins | Invoicing',
    description: `Invoicing`,
    canonical: 'https://www.milacoins.com/documentation/Invoicing',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'react,meta,document,html,tags'
      }
    }
  },
  '/documentation/GettingStarted': {
    title: 'MilaCoins | Getting Started',
    description: `Press “Sign Up” on the top right corner of the website or click “Create an account” below the login form.
    Fill in the required details (Company, Email, and Password).
    Read and Accept our “Terms of Service” & “Privacy Policy”.
    Go to your email inbox and follow the verification link to confirm your email.`,
    canonical: 'https://milacoins.com/documentation/GettingStarted',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'react,meta,document,html,tags'
      }
    }
  },
  '/documentation/Payments': {
    title: 'MilaCoins | Payment Tools',
    description: `Blockchain makes the online payment experience simple and convenient on any device. Your customer can pay with just a few taps. MilaCoins offers a set of different tools for accepting cryptocurrency payments.`,
    canonical: 'https://milacoins.com/documentation/Payments',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'react,meta,document,html,tags'
      }
    }
  },
  '/documentation/Payments/WidgetPayment': {
    title: 'MilaCoins | Payment Widget',
    description: `Do you want to accept crypto payments through your site? Use the MilaCoins payment widget. Just plug MilaCoins payment widget into your website and start running the widget.`,
    canonical: 'https://milacoins.com/documentation/Payments/WidgetPayment',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'react,meta,document,html,tags'
      }
    }
  },
  '/documentation/Payments/URLPayment': {
    title: 'MilaCoins | Request Payment',
    description: `It is possible to send payment requests by email, SMS, Messenger, WhatsApp or any other electronic means. Online Payment link is an ideal solution for companies that don’t have an ecommerce store, but who still want to accept payments from the increasing number of crypto payers.`,
    canonical: 'https://milacoins.com/documentation/Payments/URLPayment',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'react,meta,document,html,tags'
      }
    }
  },
  '/documentation/Payments/PaymentButtonStyle': {
    title: 'MilaCoins | Customize the MilaCoins Button',
    description: `A Payment Button can be styled in several different ways.`,
    canonical:
      'https://milacoins.com/documentation/Payments/PaymentButtonStyle',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'react,meta,document,html,tags'
      }
    }
  },
  '/documentation/Payments/ButtonWizard': {
    title: 'MilaCoins | Button Wizard',
    description: `By using the button wizard tool, you can generate and customize a payment button for your website.
    The wizard generates a client-side code to implement the MilaCoins widget.
    With this tool you can create unlimited buttons without any coding skills. All you need to do is to access the checkout page and to paste the code.`,
    canonical: 'https://www.milacoins.com/documentation/Payments/ButtonWizard',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'react,meta,document,html,tags'
      }
    }
  },
  '/documentation/Payments/Donate': {
    title: 'MilaCoins | Donate',
    description: `Quickly generate a donate link`,
    canonical: 'https://www.milacoins.com/documentation/Payments/Donate',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'react,meta,document,html,tags'
      }
    }
  },
  '/documentation/Plugins': {
    title: 'MilaCoins | Plugins',
    description: `Plugins help to connect your online shopping platform that supports your e-commerce operations to the automated crypto payment processing capabilities of MilaCoins Payment Gateway.`,
    canonical: 'https://www.milacoins.com/documentation/Plugins',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'react,meta,document,html,tags'
      }
    }
  },
  '/documentation/Plugins/WooCommerce': {
    title: 'MilaCoins | Plugins - WooCommerce',
    description: `The WooCommerce plugin from MilaCoins allows you to accept payments in a wide variety of crypto currencies and get the funds automatically exchanged into your preferred currency.`,
    canonical: 'https://www.milacoins.com/documentation/Plugins/WooCommerce',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'react,meta,document,html,tags'
      }
    }
  },
  '/documentation/Plugins/JumpSeller': {
    title: 'MilaCoins | Plugins - JumpSeller',
    description: `The Jumpseller plugin from MilaCoins is designed specifically to meet your business needs and help you add extra payment methods for your customers to choose from on their checkout page. This plugin allows you to accept all crypto currencies supported by MilaCoins.`,
    canonical: 'https://www.milacoins.com/documentation/Plugins/JumpSeller',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'react,meta,document,html,tags'
      }
    }
  },
  '/siteMap': {
    title: 'MilaCoins | Site Map',
    description: `MilaCoins Site Map`,
    canonical: 'https://www.milacoins.com/siteMap',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'react,meta,document,html,tags'
      }
    }
  },
  '/careers': {
    title: 'MilaCoins | Careers',
    description: `Join us, With MilaCoins, businesses and entrepreneurs have new ways to engage with consumers on a global scale. We provide a well-rounded employment experience, and we invest in our employees so that they can develop meaningful, rewarding careers. We believe in people and value our talented workers.`,
    canonical: 'https://www.milacoins.com/careers',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'react,meta,document,html,tags'
      }
    }
  },
  '/cookies-policy': {
    title: 'MilaCoins | Cookies Policy',
    description: `MilaCoins cookies policy`,
    canonical: 'https://www.milacoins.com/cookies-policy',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'react,meta,document,html,tags'
      }
    }
  },
  '/not-allowed-countries': {
    title: 'MilaCoins | Not Allowed Countries',
    description: `MilaCoins does not provide services to merchants or users residing in certain countries and territories, based on specific laws and regulations. MilaCoins regularly reviews its products, offerings, countries, and regulations, and updates its prohibited countries list as necessary.`,
    canonical: 'https://www.milacoins.com/not-allowed-countries',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'react,meta,document,html,tags'
      }
    }
  },
  '/live-demo': {
    title: 'MilaCoins | Live Demo',
    description: `Live demo payment widget`,
    canonical: 'https://www.milacoins.com/live-demo',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'react,meta,document,html,tags'
      }
    }
  },
  '/documentation/Testing': {
    title: 'MilaCoins | Testing',
    description: `For development and testing, MilaCoins users can access https://sandbox.milacoins.com/, a clone of our production site running on testnet.`,
    canonical: 'https://milacoins.com/documentation/Testing',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'react,meta,document,html,tags'
      }
    }
  },
  '/documentation/SupportedCurrencies': {
    title: 'MilaCoins | Supported Currencies',
    description: `BTC, BCH, ETH, USDT`,
    canonical: 'https://milacoins.com/documentation/SupportedCurrencies',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'react,meta,document,html,tags'
      }
    }
  },

  '/documentation/APISetup': {
    title: 'MilaCoins | API Setup',
    description: `Full official REST API documentation: api.milacoins.com`,
    canonical: 'https://milacoins.com/documentation/APISetup',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'react,meta,document,html,tags'
      }
    }
  },
  '/documentation/Webhooks': {
    title: 'MilaCoins | Webhooks',
    description: `Webhooks`,
    canonical: 'https://milacoins.com/documentation/Webhooks',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'react,meta,document,html,tags'
      }
    }
  },
  '/documentation': {
    title: 'MilaCoins | Documentation',
    description: `Get unparalleled benefits both for your company and for your customers with a set of payment tools offered by MilaCoins.`,
    canonical: 'https://milacoins.com/documentation',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'react,meta,document,html,tags'
      }
    }
  }
};
