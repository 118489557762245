import { useState, useCallback } from 'react';

const FIRST_STEP = 0;

function useStep({ steps, initialStep = FIRST_STEP }) {
  const initialCompleted = steps.map(() => false);
  const [completed, setCompleted] = useState(initialCompleted);
  const [index, setIndex] = useState(initialStep);
  const step = steps[index];

  const inRange = useCallback(
    index => {
      if (typeof index === 'number') {
        if (index < FIRST_STEP) return FIRST_STEP;
        if (index >= steps.length) return steps.length - 1;
        return index;
      }
      return steps.findIndex(step => step.id === index) || FIRST_STEP;
    },
    [steps]
  );

  const go = useCallback(step => setIndex(inRange(step)), [inRange]);
  const next = useCallback(() => go(index + 1), [go, index]);
  const prev = useCallback(() => go(index - 1), [go, index]);

  const complete = useCallback(
    (step = index) => {
      const index = inRange(step);
      const id = steps[index].id;

      setCompleted(prev => {
        const temp = [...prev];
        temp[id] = true;
        return temp;
      });
    },
    [index, inRange, steps]
  );
  const uncomplete = (step = index) => {
    const index = inRange(step);
    const stepId = steps[index].id;

    setCompleted(completed.filter(id => id !== stepId));
  };

  const uncompleteAll = () => {
    setCompleted([]);
  };

  const reset = (step = initialStep) => {
    setIndex(step);
    setCompleted([]);
  };

  return {
    complete,
    completed,
    uncompleteAll,
    index,
    navigation: { next, prev, go },
    step,
    steps,
    uncomplete,
    reset
  };
}

export default useStep;
