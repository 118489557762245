import Position from './position';

class PositionsList {
  constructor(positions) {
    this.list = positions.map(el => new Position(el));
  }

  getList() {
    return this.list;
  }

  getListLen() {
    return this.list.length;
  }

  getFilteredList(wordsOfSearch) {
    if (wordsOfSearch.length < 1) return this.list;
    const temp = [];
    const filtered = [];
    this.list.forEach(position => {
      let element = [];
      element.push(position.getLocation().country);
      element.push(position.getLocation().name);
      element.push(position.getCategory().name);
      element.push(position.getTitle());
      temp.push(element);
    });
    temp.forEach((element, index) => {
      if (wordsOfSearch.some(word => element.includes(word))) {
        filtered.push(this.list[index]);
      }
    });
    return filtered;
  }

  getListGroupedBy(index) {
    return Array.groupBy(this.list, index);
  }
}

export default PositionsList;
