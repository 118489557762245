import React from 'react';
import PAGE from '../../../../components/page';
import { H1, P, H4, STRONG, LINK, BR, UL, LI } from '../../../../components';
import { SHARPIMAGE } from '../../../../components/images';
import img1 from './jumpseller-external.png';
import img2 from './jumpseller-configure.png';
import img3 from './jumpseller-configure2.png';

const Index = () => {
  return (
    <PAGE>
      <H1>Jumpseller</H1>
      <P>
        The Jumpseller plugin from MilaCoins is designed specifically to meet
        your business needs and help you add extra payment methods for your
        customers to choose from on their checkout page. This plugin allows you
        to accept all crypto currencies supported by MilaCoins.
      </P>
      <P>
        <STRONG>
          Follow the 2 simple steps below to add the MilaCoins payment method to
          your store:
        </STRONG>
      </P>
      <UL>
        <LI>Add “External Payment Gateway” on your jumpseller admin.</LI>
        <LI>Configure the keys from MilaCoins account.</LI>
      </UL>
      <BR />
      <BR />
      <H4>Add External Payment Gateway as payment method</H4>
      <UL>
        <LI>
          <LINK to="https://jumpseller.com/login/" target={'_blank'}>
            Login
          </LINK>{' '}
          to JumpSeller admin panel.
        </LI>
        <LI>
          Navigate to <STRONG>Settings</STRONG> {'>'} <STRONG>Payments</STRONG>{' '}
          and from the dropdown menu, select{' '}
          <STRONG>External Payment Gateway </STRONG>option {'>'}{' '}
          <STRONG>Add method</STRONG>.
        </LI>
      </UL>
      <SHARPIMAGE>{img1}</SHARPIMAGE>
      <BR />
      <BR />
      <BR />
      <BR />
      <H4>Configure MilaCoins account keys</H4>
      <UL>
        <LI>
          <LINK to="https://milacoins.com/login" target={'_blank'}>
            Log in
          </LINK>{' '}
          to your MilaCoins account -{' '}
          <LINK to="https://milacoins.com/create-account">click here </LINK> if
          you do not have one.
        </LI>
        <LI>
          Navigate to{' '}
          <LINK
            to="https://milacoins.com/backoffice/payments-tools/plugins/jumpseller"
            target={'_blank'}
          >
            JumpSeller Page
          </LINK>
          : <STRONG>Payment Tools</STRONG> {'>'}
          <STRONG>Setup Payment Button</STRONG>
          {'>'}
          <STRONG>JumpSeller</STRONG>.
        </LI>
        <LI>
          Select the wallet you wish to receive your automatically converted
          payments to.
        </LI>
        <LI>
          Copy all the "Payment Methods" details from your MilaCoins account
          specifically and paste on JumpSeller “External Payment Gateway”.
        </LI>
        <LI>
          Press "SAVE" (on JumpSeller “External Payment Gateway”). If MilaCoins
          has been added, you will see it in the list of payment gateways.
        </LI>
      </UL>
      <SHARPIMAGE
        description="*The screenshot from MilaCoins dashboard with the info that needs to be
      copied"
      >
        {img2}
      </SHARPIMAGE>
      <SHARPIMAGE
        description="*The screenshot from MilaCoins dashboard with the info that needs to be
      copied"
      >
        {img3}
      </SHARPIMAGE>
    </PAGE>
  );
};

export default Index;
