import { useEffect } from 'react';
import { useRouter } from 'myHooks';

const useScrollToTop = () => {
  const { pathname, query, history } = useRouter();

  useEffect(() => {
    const unlisten = history.listen(() => {
      try {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
      } catch (error) {
        window.scrollTo(0, 0);
      }
    });
    return () => {
      unlisten();
    };
  }, [history, pathname, query]);
};

export default useScrollToTop;
