import React, { useState } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { a11yDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Popper from 'popper.js';
import { MdContentCopy } from 'react-icons/md';
import 'css/components/tooltips.css';

export const CODEBLOCK = ({ children, language }) => {
  const [tooltipShow, setPopoverShow] = useState(false);
  const btnRef = React.createRef();
  const tooltipRef = React.createRef();
  const openPopover = str => {
    new Popper(btnRef.current, tooltipRef.current, {
      placement: 'top'
    });
    setPopoverShow(str);
  };
  return (
    <>
      <CopyToClipboard
        text={children}
        className="cursor-pointer relative"
        onCopy={() => openPopover('Copied')}
      >
        <div onMouseLeave={() => setPopoverShow(false)} ref={btnRef}>
          <SyntaxHighlighter
            className="break-words text-xs relative md:text-sm lg:text-base"
            language={language}
            style={a11yDark}
            lineProps={{
              style: {
                wordBreak: 'break-all',
                whiteSpace: 'pre-wrap'
              }
            }}
            wrapLines={true}
          >
            {children}
          </SyntaxHighlighter>
          <span className="absolute right-0 -mt-10 mr-3 text-white">
            <MdContentCopy size="30" />
          </span>
        </div>
      </CopyToClipboard>
      <div
        className={`tooltip ${tooltipShow ? 'tooltip-show ' : ''}`}
        ref={tooltipRef}
      >
        <div className="tooltip-content ml-2 text-mila-300">{tooltipShow}</div>
      </div>
    </>
  );
};

export const LINE = ({ children }) => {
  return <div className="flex flex-row">{children}</div>;
};

export const TAB = () => {
  return <div className="ml-5" />;
};

export const SPACE = () => {
  return <div className="ml-1" />;
};

export const RED = ({ children }) => {
  return <div className="text-red-400">{children}</div>;
};

export const YELLOW = ({ children }) => {
  return <div className="text-yellow-400">{children}</div>;
};

export const GREEN = ({ children }) => {
  return <div className="text-green-400">{children}</div>;
};

export const ORANGE = ({ children }) => {
  return <div className="text-orange-400">{children}</div>;
};

export const BLUE = ({ children }) => {
  return <div className="text-blue-700">{children}</div>;
};

export const PURPLE = ({ children }) => {
  return <div className="text-purple-300">{children}</div>;
};

export const LIGHTBLUE = ({ children }) => {
  return <div className="text-blue-200">{children}</div>;
};

export const WHITE = ({ children }) => {
  return <div className="text-white">{children}</div>;
};

export const GREY = ({ children }) => {
  return <div className="text-grey-400">{children}</div>;
};

export const COMMENT = ({ children }) => {
  return <div className="text-green-800">{`//${children}`}</div>;
};
