//
Array.groupBy = function groupBy(array, by) {
  const keys = by.split('.');
  const getKey = (obj, deepens = 0) => {
    if (deepens === keys.length - 1) return obj[keys[deepens]];
    else return getKey(obj[keys[deepens]], deepens + 1);
  };

  return array.reduce((acc, obj) => {
    const key = getKey(obj);
    acc[key] = acc[key] || [];
    acc[key].push(obj);
    return acc;
  }, {});
};

export function groupPositionsByLocation(positions) {
  return Array.groupBy(positions, 'location.code');
}
