import React, { lazy, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import { RoutesOfDocs } from 'pages/documentation/navigation';
import Waiting from 'components/widgets/waiting';

// //public
const WaitingList = lazy(() => import('pages/public/waitingList'));
const CreateAccount = lazy(() => import('pages/public/createAccount'));
const EmailConfirmation = lazy(() => import('pages/public/email-confirmation'));
const ErrorPage = lazy(() => import('pages/Errors/error-page'));
const ErrorPage500 = lazy(() => import('pages/Errors/error-page500'));
const TokenExpired = lazy(() => import('pages/Errors/error-page-token-over'));
const Timeout = lazy(() => import('pages/Errors/time-out'));
const Login = lazy(() => import('pages/public/Login'));
const SiteMap = lazy(() => import('pages/public/siteMap'));
const Homepage = lazy(() => import('pages/public/homepage'));
const About = lazy(() => import('pages/public/homepage/about'));
const Careers = lazy(() => import('pages/public/homepage/careers'));
const CareersForm = lazy(() =>
  import('pages/public/homepage/careers/positionPage/form')
);
const LinkedInCallBack = lazy(() =>
  import('pages/public/homepage/careers/positionPage/auth/linkedIn/callBack')
);
const WhyUs = lazy(() => import('pages/public/homepage/whyUs'));
const Pricing = lazy(() => import('pages/public/homepage/pricing'));
const Fees = lazy(() => import('pages/public/homepage/pricing/fees'));
const Contact = lazy(() => import('pages/public/homepage/contact'));
const HowItWorks = lazy(() => import('pages/public/homepage/howItWorks'));
const FAQ = lazy(() => import('pages/public/homepage/faq'));
const NotAllowedCountries = lazy(() =>
  import('pages/public/homepage/notAllowedCountries')
);
const GetStarted = lazy(() => import('pages/public/homepage/getStarted'));
const ECommerce = lazy(() =>
  import('pages/public/homepage/whoIsItFor/infoPages/eCommers')
);
const Professionals = lazy(() =>
  import('pages/public/homepage/whoIsItFor/infoPages/professional')
);
const ITDev = lazy(() =>
  import('pages/public/homepage/whoIsItFor/infoPages/it')
);
const Charities = lazy(() =>
  import('pages/public/homepage/whoIsItFor/infoPages/charities')
);
const MerchantsPublic = lazy(() =>
  import('pages/public/homepage/whoIsItFor/infoPages/merchants')
);
const Logout = lazy(() => import('pages/public/logout'));
const EmailVerified = lazy(() => import('pages/public/email-verified'));
const PrivacyPolicy = lazy(() => import('pages/privacy-policy'));
const CookiesPolicy = lazy(() => import('pages/cookies-policy'));
const AMLPolicy = lazy(() => import('pages/aml-policy'));
const ForgotPassword = lazy(() => import('pages/public/forgot-password'));
const TermsOfService = lazy(() => import('pages/terms-of-service'));

const LoginDemo = lazy(() => import('pages/public/Login/LoginDemo'));

const InvoiceCheckout = lazy(() => import('pages/invoice/checkout'));
const Refund = lazy(() => import('pages/invoice/refund'));
const LiveDemo = lazy(() => import('pages/public/homepage/liveDemo'));
const WidgetPayment = lazy(() => import('./pages/invoice/widget'));

const Routes = () => {
  return (
    <Suspense
      fallback={
        <div>
          <Waiting size="xl" />
        </div>
      }
    >
      <Switch>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/demo-login">
          <LoginDemo />
        </Route>

        <Route path="/siteMap">
          <SiteMap />
        </Route>
        <Route path="/why-us">
          <WhyUs />
        </Route>
        <Route path="/about-us">
          <About />
        </Route>
        <Route exact path="/">
          <Homepage />
        </Route>
        <Route exact path="/waiting-list">
          <WaitingList />
        </Route>
        <Route exact path="/not-allowed-countries">
          <NotAllowedCountries />
        </Route>
        <Route exact path="/careers/apply">
          <CareersForm />
        </Route>
        <Route exact path="/careers">
          <Careers />
        </Route>
        <Route exact path="/careers/linkedIn">
          <LinkedInCallBack />
        </Route>
        <Route exact path="/eCommerce">
          <ECommerce />
        </Route>
        <Route exact path="/professionalsServices">
          <Professionals />
        </Route>
        <Route exact path="/ITDevelopers">
          <ITDev />
        </Route>
        <Route exact path="/charities">
          <Charities />
        </Route>
        <Route exact path="/merchants">
          <MerchantsPublic />
        </Route>
        <Route exact path="/pricing/fees">
          <Fees />
        </Route>
        <Route exact path="/pricing">
          <Pricing />
        </Route>
        <Route exact path="/contact-us">
          <Contact />
        </Route>
        <Route exact path="/how-it-works">
          <HowItWorks />
        </Route>
        <Route exact path="/live-demo">
          <LiveDemo />
        </Route>
        <Route exact path="/FAQ">
          <FAQ />
        </Route>
        <Route exact path="/get-started">
          <GetStarted />
        </Route>
        <Route exact path="/verify-email">
          <EmailVerified />
        </Route>
        <Route exact path="/invoice">
          <InvoiceCheckout />
        </Route>
        <Route exact path="/WidgetPayment">
          <WidgetPayment />
        </Route>
        <Route exact path="/invoice/refund">
          <Refund />
        </Route>
        <Route path="/email-confirmation">
          <EmailConfirmation />
        </Route>
        <Route path="/error-page">
          <ErrorPage />
        </Route>
        <Route path="/error-page500">
          <ErrorPage500 />
        </Route>
        <Route path="/error-token-expired">
          <TokenExpired />
        </Route>
        <Route path="/error-timeout">
          <Timeout />
        </Route>
        <Route path="/forgot-password">
          <ForgotPassword />
        </Route>
        <Route path="/privacy-policy">
          <PrivacyPolicy />
        </Route>
        <Route path="/cookies-policy">
          <CookiesPolicy />
        </Route>

        <Route path="/aml-policy">
          <AMLPolicy />
        </Route>
        <Route path="/terms-of-service">
          <TermsOfService />
        </Route>
        <Route path="/logout">
          <Logout />
        </Route>
        <Route path="/create-account">
          <CreateAccount />
        </Route>

        {RoutesOfDocs.map(({ path, Component }, key) => {
          return (
            <Route key={`${path}-${key}`} path={path}>
              <Component />
            </Route>
          );
        })}
      </Switch>
    </Suspense>
  );
};
export default Routes;
