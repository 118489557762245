import { useEffect, useState } from 'react';

const types = [
  { mimetype: 'application/pdf', type: 'pdf' },
  { mimetype: 'image/png', type: 'png' },
  { mimetype: 'image/jpg', type: 'jpg' },
  { mimetype: 'image/jpeg', type: 'jpeg' },
  { mimetype: 'application/msword', type: 'doc' },
  {
    mimetype:
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    type: 'docx'
  }
];

const useFetchFile = src => {
  const [file, setFile] = useState({});
  const [status, setStatus] = useState('idle');  useEffect(() => {

    if (src) {
      setStatus('pending');
      fetch(src)
        .then(res => res.blob())
        .then(fetchFile => {
          const f = types.find(({ mimetype }) => mimetype === fetchFile.type);
          if (f) {
            setFile({
              src: URL.createObjectURL(fetchFile),
              type: f.type
            });
            setStatus('success');
          } else setStatus('error');
        })
        .catch(err => {
          setStatus('error');
          setFile({});
        });
    }
  }, [src, setFile, setStatus]);

  return {
    file, 
    status,
    error: status === 'error',
    isLoading: status === 'pending'
  };
};
export default useFetchFile;
