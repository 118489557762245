export const Symbols = {
  USDC: {
    code: 'USDC',
    name: 'USD Coin',
    symbol: 'USDC',
    isCrypto: true,
    isWeb3: true,
    isToken: true,
    network: 'ETH',
    decimals: 6,
    address: process.env.USDC_ADDRESS,
    roundDigits: 2,
    icon: 'https://milacoins.com/static/assets/usdcicon.png'
  },
  USDP: {
    code: 'USDP',
    name: 'Pax Dollar',
    symbol: 'USDP',
    isCrypto: true,
    isWeb3: true,
    isToken: true,
    network: 'ETH',
    decimals: 18,
    address: process.env.USDP_ADDRESS,
    roundDigits: 2,
    icon: 'https://milacoins.com/static/assets/usdpicon.png'
  },
  AED: {
    code: 'AED',
    name: 'UAE dirham',
    symbol: 'د.إ',
    isCrypto: false,
    roundDigits: 2
  },
  BRL: {
    code: 'BRL',
    name: 'Brazilian real',
    symbol: 'R$',
    isCrypto: false,
    roundDigits: 2
  },
  BTC: {
    code: 'BTC',
    name: 'Bitcoin',
    symbol: 'Ƀ',
    isCrypto: true,
    roundDigits: 5,
    icon: 'https://milacoins.com/static/assets/btcicon.png',
    addressMinLength: 34
  },
  ETH: {
    code: 'ETH',
    name: 'Ethereum',
    symbol: 'Ξ',
    isCrypto: true,
    roundDigits: 5,
    icon: 'https://milacoins.com/static/assets/ethicon.png',
    addressMinLength: 42
  },
  BCH: {
    code: 'BCH',
    name: 'Bitcoin Cash',
    symbol: 'BCH',
    isCrypto: true,
    roundDigits: 5,
    icon: 'https://milacoins.com/static/assets/bchicon.png',
    addressMinLength: 26
  },
  EUR: {
    code: 'EUR',
    name: 'Euro',
    symbol: '€',
    isCrypto: false,
    roundDigits: 2
  },
  USD: {
    code: 'USD',
    name: 'US Dollar',
    symbol: '$',
    isCrypto: false,
    roundDigits: 2
  },
  USDT: {
    code: 'USDT',
    name: 'Tether US',
    symbol: '₮',
    isCrypto: true,
    roundDigits: 2,
    icon: 'https://milacoins.com/static/assets/usdticon.png',
    addressMinLength: 42
  },
  GBP: {
    code: 'GBP',
    name: 'Pound sterling',
    symbol: '£',
    isCrypto: false,
    roundDigits: 2
  },
  ILS: {
    code: 'ILS',
    name: 'Israeli new shekel',
    symbol: '₪',
    isCrypto: false,
    roundDigits: 2
  },
  JPY: {
    code: 'JPY',
    name: 'Japanese yen',
    symbol: '¥',
    isCrypto: false,
    roundDigits: 2
  },
  MYR: {
    code: 'MYR',
    name: 'Malaysian ringgit',
    symbol: 'RM',
    isCrypto: false,
    roundDigits: 2
  },
  NZD: {
    code: 'NZD',
    name: 'N.Zealand dollar',
    symbol: 'NZ$',
    isCrypto: false,
    roundDigits: 2
  },
  RUB: {
    code: 'RUB',
    name: 'Russian ruble',
    symbol: '₽',
    isCrypto: false,
    roundDigits: 2
  },
  SEK: {
    code: 'SEK',
    name: 'Swedish crown',
    symbol: 'kr',
    isCrypto: false,
    roundDigits: 2
  },
  TRY: {
    code: 'TRY',
    name: 'Turkish lira',
    symbol: '₺',
    isCrypto: false,
    roundDigits: 2
  },
  UAH: {
    code: 'UAH',
    name: 'Ukrainian hryvnia',
    symbol: '₴',
    isCrypto: false,
    roundDigits: 2
  }
};
