import React, { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { ModalHeader } from './header';
import { ModalBody } from './body';
import { ModalFooter } from './footer';
import '../../css/components/modals.css';

const Modal = ({
  specialSize,
  children,
  title,
  bodyRender,
  footerRender,
  visible = false,
  setVisible
}) => {
  const dispatch = useDispatch();
  const hide = useCallback(() => {
    setVisible(false);
    dispatch({
      type: 'SET_CONFIG_KEY',
      key: 'backdrop',
      value: false
    });
  }, [dispatch, setVisible]);
  const show = useCallback(() => {
    setVisible(true);
    dispatch({
      type: 'SET_CONFIG_KEY',
      key: 'backdrop',
      value: true
    });
  }, [dispatch, setVisible]);

  useEffect(() => {
    if (visible) show();
    else hide();
  }, [visible, hide, show]);

  return (
    <>
      {visible && (
        <>
          <div className="flex w-full dark:bg-dark-95 dark:border-dark-90"></div>

          <div className="modal show">
            <div
              className={`relative  p-3 lg:my-4 mx-auto ${
                specialSize && specialSize !== ''
                  ? specialSize
                  : 'sm:max-w-xl max-w-sm'
              } `}
            >
              <div className="modal-content">
                <ModalHeader title={title} onClick={hide} />
                <ModalBody rander={bodyRender} />
                <ModalFooter rander={footerRender} />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Modal;
