import React from 'react';
import { H1, P, STRONG, LINK, UL, LI } from '../../../components';
import PAGE from '../../../components/page';
import { TABLE, THEAD, TH, TBODY, TD, TR } from '../../../components/table';

const Index = () => {
  return (
    <PAGE>
      <H1>Environments</H1>
      <P>
        In addition to the Production environment, MilaCoins also provides
        developers with the Sandbox test environment.
      </P>
      <P>
        The Sandbox environment is an isolated virtual server environment that
        permits testing the system's functionality. If you want to get the
        system tested internally, you will need to set up the Sandbox account
        first.
      </P>

      <TABLE>
        <THEAD>
          <TH size="1/2">
            <STRONG>Environment</STRONG>
          </TH>
          <TH size="1/2">
            <STRONG>URL</STRONG>
          </TH>
        </THEAD>
        <TBODY>
          <TR>
            <TD size="1/2">Sandbox</TD>
            <TD size="1/2">
              <LINK to="https://sandbox.milacoins.com/">
                https://sandbox.milacoins.com/
              </LINK>
            </TD>
          </TR>

          <TR>
            <TD size="1/2">Production</TD>
            <TD size="1/2">
              <LINK to="https://www.milacoins.com/">
                https://www.milacoins.com/
              </LINK>
            </TD>
          </TR>
        </TBODY>
      </TABLE>

      <P>
        The Sandbox Environment and the Production Environment are independent
        environments and are not connected to each other in any way.
      </P>

      <P>
        <STRONG>Testnet faucet (for sandbox environment) </STRONG>

        <UL>
          <LI>
            <LINK to="https://coinfaucet.milacoins.com/bitcoin.html">
              coinfaucet-bitcoin milacoins
            </LINK>
          </LI>
          <LI>
            <LINK to="https://coinfaucet.milacoins.com/ethereum.html">
              Ethereum ERC-20 coin faucet
            </LINK>
          </LI>
        </UL>
      </P>
    </PAGE>
  );
};

export default Index;
