import React from 'react';
import { H1, P } from '../../../../components';
import PAGE from '../../../../components/page';
import { SHARPIMAGE } from '../../../../components/images';
import steps from './steps.png';
import info from './info.png';

const Index = () => {
  return (
    <PAGE>
      <H1>Withdraw</H1>
      <P>
        MilaCoins is a crypto-to-fiat service, which means that you can link
        your bank account directly to your MilaCoins account. Funds can only be
        withdrawn from fiat wallets. To withdraw funds from your crypto wallet,
        you will need to convert them to fiat first.{' '}
      </P>

      <P>
        To withdraw your money from one of MilaCoins fiat wallets, you need to
        log in to your account, make sure that you have enough funds on your
        balance, and click the WITHDRAW button.
      </P>
      <SHARPIMAGE>{steps}</SHARPIMAGE>
      <P>
        The funds will be automatically transferred to your bank account within
        24-48 hours. There is a 3% fee on all withdrawals. This fee includes a
        standard MilaCoins fee plus your bank transaction fee.
      </P>
      <P>
        Please note that withdrawals are available only to MilaCoins users who
        have successfully passed the KYB verification process. The latter
        requires you to submit a set of documents that include your company
        name, proof of residence, etc. As a rule, it takes 1-2 business days to
        get your documents verified.
      </P>
      <P>
        Also, you need to add your banking info as the funds cannot be withdrawn
        until it gets verified.
      </P>
      <SHARPIMAGE>{info}</SHARPIMAGE>
    </PAGE>
  );
};

export default Index;
