import React from 'react';
import PAGE from '../../../components/page';
import { H1, H4, P, UL, LI, STRONG, LINK, BR } from '../../../components';
import { CODEBLOCK } from '../../../components/code';
import { TABLE, THEAD, TH, TBODY, TD, TR } from '../../../components/table';
import { SHARPIMAGE } from '../../../components/images';
import f from './f.png';

const Index = () => {
  return (
    <PAGE>
      <H1>Payment Widget</H1>
      <P>
        Do you want to accept crypto payments through your site? Use the
        Milacoins payment widget. Just add it to your website and start running
        the widget.
      </P>
      <P>
        Integration Widget is a great option for e-commerce site owners who wish
        to accept cryptocurrency payments from their customers.
      </P>
      <H4>Steps overview:</H4>
      <P>
        <UL>
          <STRONG>Follow two simple steps:</STRONG>
          <P>Step 1. Generate a widget key on the BackOffice.</P>
          <P>
            Step 2. Install an NPM package or use the CDN version of the script
            on the front - end and run the widget with the widget key from Step
            one.
          </P>
        </UL>
      </P>

      <H4>Generate widget key:</H4>

      <P>
        <UL>
          <STRONG>
            Log in to your account: <LINK to="/login">Login</LINK>
          </STRONG>
          <LI>
            Navigate to{' '}
            <LINK
              to="https://milacoins.com/backoffice/payments-tools/widget/javascript"
              target={'_blank'}
            >
              JS Code integrations
            </LINK>{' '}
            : <STRONG>Payment Tools</STRONG> {'>'}
            <STRONG>Setup Payment Button</STRONG>
            {'>'}
            <STRONG>Code integrations - Java Script</STRONG>.
          </LI>
          <LI>Copy the widget key</LI>
          <SHARPIMAGE height="50">{f}</SHARPIMAGE>
        </UL>
        <H4>Front-end integration:</H4>
        <UL>
          <LI>Install the widget with NPM or by adding a Script tag</LI>
          <UL>
            <LI>
              <STRONG>NPM</STRONG> - <code>npm i milacoins-widget</code>
            </LI>
            <LI>
              <STRONG>Script tag </STRONG>-
              <CODEBLOCK language="html">
                {`<script src="https://milacoins.com/static/mila-coins-widget-min_v2.js"></script>`}
              </CODEBLOCK>
            </LI>
          </UL>
          <LI>
            <STRONG>
              Create container(div) for the Widget on your web page:{' '}
            </STRONG>
            <CODEBLOCK language="html">
              {'<div id="milacoins-widget-con"></div>'}
            </CODEBLOCK>
          </LI>
          <LI>
            <STRONG>Run the widget:</STRONG>
          </LI>
          <UL>
            <LI>
              <STRONG>NPM:</STRONG>
              <CODEBLOCK language="javascript">
                {`import MilaCoin from "milacoins-widget";

MilaCoin.button({
  widgetKey,
  elID: "milacoins-widget-con",
  amount,
  currency,
  walletTarget,
  externalID,
  onError,
  onSuccess,
  customer,
  mode  
});`}
              </CODEBLOCK>
            </LI>
            <LI>
              <STRONG>Script tag:</STRONG>
              <CODEBLOCK language="html">
                {`<script>
  milacoins.button({
  widgetKey,
  elID: "milacoins-widget-con",
  amount,
  currency,
  walletTarget,
  externalID,
  onError:(error)=>{console.log(error,"error")},
  onSuccess:(data)=>{console.log(data,"invoice")},
  customer:{
  firstName
  lastName
  email,
  },
  mode:"sandbox"
  });
</script>`}
              </CODEBLOCK>
            </LI>
          </UL>
        </UL>
      </P>

      <H4>Widget Options:</H4>

      <TABLE>
        <THEAD>
          <TH size="1/4">
            <STRONG>Field</STRONG>
          </TH>
          <TH size="1/4">
            <STRONG>Type</STRONG>
          </TH>
          <TH size="1/4">
            <STRONG>Description</STRONG>
          </TH>
          <TH size="1/4">
            <STRONG>Required</STRONG>
          </TH>
        </THEAD>
        <TBODY>
          <TR>
            <TD size="1/4">widgetKey</TD>
            <TD size="1/4">String</TD>
            <TD size="1/4">Your widget key</TD>
            <TD size="1/4">required</TD>
          </TR>
          <TR>
            <TD size="1/4">elID</TD>
            <TD size="1/4">String</TD>
            <TD size="1/4">
              The place on your web page where the widget can be plugged in
            </TD>
            <TD size="1/4">required</TD>
          </TR>
          <TR>
            <TD size="1/4">currency </TD>
            <TD size="1/4">String</TD>
            <TD size="1/4">
              Invoice currency, supported currencies:{' '}
              <LINK to="/documentation/SupportedCurrencies">
                <span className="text-sm"> click here</span>
              </LINK>
            </TD>
            <TD size="1/4">required</TD>
          </TR>
          <TR>
            <TD size="1/4">amount</TD>
            <TD size="1/4">Number</TD>
            <TD size="1/4">invoice amount</TD>
            <TD size="1/4">required</TD>
          </TR>
          <TR>
            <TD size="1/4">walletTarget</TD>
            <TD size="1/4">String</TD>
            <TD size="1/4">
              Incoming wallet for the payment, supported wallets:{' '}
              <LINK to="/documentation/Wallet/SupportedWallets">
                <span className="text-sm"> click here</span>
              </LINK>
            </TD>
            <TD size="1/4">required</TD>
          </TR>
          <TR>
            <TD size="1/4">externalID</TD>
            <TD size="1/4">String</TD>
            <TD size="1/4"></TD>
            <TD size="1/4">not required</TD>
          </TR>
          <TR>
            <TD size="1/4">embedded</TD>
            <TD size="1/4">Boolean</TD>
            <TD size="1/4">
              If false, the widget will pop up on the page. If true, the widget
              will be embedded. False by default.
            </TD>
            <TD size="1/4">not required</TD>
          </TR>
          <TR>
            <TD size="1/4">style</TD>
            <TD size="1/4">object</TD>
            <TD size="1/4">
              For details about the button style{' '}
              <LINK to="/documentation/Payments/PaymentButtonStyle">
                <span className="text-sm"> click here</span>
              </LINK>
            </TD>
            <TD size="1/4">not required</TD>
          </TR>
          <TR>
            <TD size="1/4">onError</TD>
            <TD size="1/4">function</TD>
            <TD size="1/4"></TD>
            <TD size="1/4">not required</TD>
          </TR>
          <TR>
            <TD size="1/4">onStart</TD>
            <TD size="1/4">function</TD>
            <TD size="1/4">
              Function to be called upon widget opening, get data object with:{' '}
              {`{id,
          amount,
          currency,
          externalID,
          createdAt}`}
            </TD>
            <TD size="1/4">not required</TD>
          </TR>
          <TR>
            <TD size="1/4">onSuccess</TD>
            <TD size="1/4">function</TD>
            <TD size="1/4">
              Function that gets data object with:{' '}
              {`{id,
          amount,
          currency,
          externalID,
          createdAt}`}
            </TD>
            <TD size="1/4">not required</TD>
          </TR>
          <TR>
            <TD size="1/4">customer</TD>
            <TD size="1/4">Object</TD>
            <TD size="1/4">the default customer details</TD>
            <TD size="1/4">not required</TD>
          </TR>
          <TR>
            <TD size="1/4">
              <pre> firstName</pre>
            </TD>
            <TD size="1/4">String</TD>
            <TD size="1/4">
              the default first name that will be shown on the widget
            </TD>
            <TD size="1/4">not required</TD>
          </TR>
          <TR>
            <TD size="1/4">
              <pre> lastName</pre>
            </TD>
            <TD size="1/4">String</TD>
            <TD size="1/4">
              the default last name that will be shown on the widget
            </TD>
            <TD size="1/4">not required</TD>
          </TR>
          <TR>
            <TD size="1/4">
              <pre> email</pre>
            </TD>
            <TD size="1/4">String</TD>
            <TD size="1/4">
              the default email that will be shown on the widget
            </TD>
            <TD size="1/4">not required</TD>
          </TR>
          <TR>
            <TD size="1/4">mode </TD>
            <TD size="1/4">String </TD>
            <TD size="1/4">"prod" or "sandbox"</TD>
            <TD size="1/4">required</TD>
          </TR>
        </TBODY>
      </TABLE>
      {/* <BR />
      <BR />
      <H4>Libraries:</H4>
      <P>
        React:{' '}
        <LINK to="https://www.npmjs.com/package/milacoins-widget-react">
          https://www.npmjs.com/package/milacoins-widget-react
        </LINK>
      </P> */}
      <BR />
      <BR />
      <H4>
        <STRONG>Standalone working example:</STRONG>
      </H4>
      <P>
        Just change {`{{WIDGET_KEY}}`} to your widget key (from sandbox
        environment)
      </P>
      <P>
        <CODEBLOCK language="html">
          {`<html>
<head>
<title>MilaCoins Example Widget</title>
</head>

    <body>  
        <script src="https://milacoins.com/static/mila-coins-widget-min_v2.js">
        </script>
        <div id="milacoins-widget-con"></div>
    </body>

    <script>
      milacoins.button({
        widgetKey:"{{WIDGET_KEY}}",
        elID: "milacoins-widget-con",
        amount:15,
        currency:"EUR",
        walletTarget:"BTC",
        externalID:"ExampleID",
        onError:(error)=>{console.log(error,"error")},
        onSuccess:(data)=>{console.log(data,"invoice")},
        customer:{
          firstName:"fName",
          lastName:"lName",
          email:"example@milacoins.com",
        },
        mode:"sandbox"
      });
    </script>

</html>
          `}
        </CODEBLOCK>
      </P>
      <P>
        Next step is to make sure you have completed payment. You'll see it on
        the active tab of the dashboard.
      </P>
      <P>
        * The{' '}
        <LINK to="https://coinfaucet.milacoins.com/bitcoin.html">
          testnet wallet{' '}
        </LINK>{' '}
        can be used to complete payments if you use the "sandbox" mode and you
        are paying using the Bitcoin network.
      </P>
    </PAGE>
  );
};

export default Index;
