import React from 'react';
import { H1, P, H4, UL, LI, STRONG, BR, LINK } from '../../../components';
import PAGE from '../../../components/page';
import { SHARPIMAGE } from '../../../components/images';
import img1 from './img1.png';
import img2 from './img2.png';
import img3 from './img3.png';
import img4 from './img4.png';

const Index = () => {
  return (
    <PAGE>
      <H1>API Setup</H1>

      <H4>API Documentation:</H4>
      <P>
        Full official REST API documentation:{' '}
        <LINK to="http://api.milacoins.com">api.milacoins.com</LINK>
      </P>
      <BR />
      <BR />
      <H4> Get started:</H4>
      <P>
        This tutorial shows you how to activate access to the MilaCoins REST
        API.
      </P>
      <P>
        To use the Milacoins API, it is important that you understand the basics
        of RESTful web services and JSON representations.
      </P>
      <BR />
      <BR />
      <H4> Get keys and access:</H4>
      <UL>
        <LI>
          <STRONG>Navigate to the settings API</STRONG>
        </LI>
        <SHARPIMAGE>{img1}</SHARPIMAGE>
        <BR />
        <BR />
        <LI>
          <STRONG>Generate an API key</STRONG>
        </LI>
        <SHARPIMAGE>{img2}</SHARPIMAGE>
        <BR />
        <BR />
        <LI>
          <STRONG>Save the Secret Key</STRONG>
        </LI>
        <SHARPIMAGE>{img3}</SHARPIMAGE>
        <BR />
        <BR />
        <LI>
          <STRONG>
            Add an IP address that will be permitted to use the key.
          </STRONG>
        </LI>
        <SHARPIMAGE>{img4}</SHARPIMAGE>
        <BR />
        <BR />
      </UL>

      <P>
        <STRONG>
          More information about authorization, method and errors can be found
          in the <LINK to="http://api.milacoins.com"> API documentation</LINK>.
        </STRONG>
      </P>
    </PAGE>
  );
};

export default Index;
