import React from 'react';
import { H1, H4, P, STRONG, UL, LI } from '../../../components';
import { SHARPIMAGE } from '../../../components/images';
import PAGE from '../../../components/page';
import createAccount from './create-account.png';

const Index = () => {
  return (
    <PAGE>
      <H1>Create Account</H1>
      
      {/* <H4>Two Account Types</H4>
      <P>
        You can register{' '}
        <LINK to="https://sandbox.milacoins.com/create-account">
          a demo account
        </LINK>{' '}
        and/or a{' '}
        <LINK to="https://milacoins.com/create-account">
          real (production) account.
        </LINK>
      </P> */}

      <H4>Create Your MilaCoins Account</H4>
      <P>
        The first account you set up will be a root account by default. It means that you’ll be the superuser or the administrator of the account.
      </P>
      <P>
        To register an account, follow the steps below:
      </P>
      <UL>
        <LI>Click the <STRONG>“Sign up”</STRONG> button in the top right corner of the home page or click “Create account” below the login form.</LI>
        <LI>Fill in the account details, including your Company name, Email, and Password.</LI>
        <LI>Confirm your Email address to enable access to the account.</LI>
        <LI>Enable Two-Factor Authentication to enhance security of your account by clicking on the <STRONG>Settings</STRONG> icon in the top right corner of the <STRONG>"Security"</STRONG> tab.</LI>
      </UL>

      <SHARPIMAGE height="50">{createAccount}</SHARPIMAGE>
      <H4>Confirm e-mail</H4>
      <P>To enable access to the account, confirm your email address</P>

      <H4>Two-factor authentication</H4>
      <P>
        {' '}
        To enhance security and protect your account, enable two-factor
        authentication. To enable 2FA , click on the setting icon on the top
        right corner of the "Security" tab.
      </P>
    </PAGE>
  );
};

export default Index;
