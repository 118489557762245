import React from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import Invoice from './Invoice';
import PublicRoute from './public';
import Documentation from './documentation';
import { useScrollToTop, useRouter, useSelector } from 'myHooks';

const Layouts = ({ children }) => {
  useScrollToTop(); // Hook

  const { publicRoutes = [] } = useSelector(state => ({
    publicRoutes: state.routes.publicRoutes
  }));

  let { pathname } = useRouter();

  if (pathname === '/invoice' || pathname === '/WidgetPayment')
    return <Invoice>{children}</Invoice>;

  if (pathname.includes('documentation')) {
    return <Documentation>{children}</Documentation>;
  }

  if (publicRoutes.includes(pathname))
    return <PublicRoute pathname={pathname}>{children}</PublicRoute>;

  return <Redirect to="/error-page" />;
};

export default withRouter(Layouts);
