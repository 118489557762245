import React from 'react';
import Header from './header';
import Links from './links';
import DocumentMeta from 'react-document-meta';

const Index = () => {
  const meta = {
    title: 'MilaCoins | Documentation',
    description:
      'Get unparalleled benefits both for your company and for your customers with a set of payment tools offered by MilaCoins.',
    canonical: 'https://milacoins.com/documentation',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'react,meta,document,html,tags'
      }
    }
  };

  return (
    <div className="pt-20">
      <DocumentMeta {...meta} />
      <Header />
      <Links />
    </div>
  );
};

export default Index;
