import React from 'react';
import { ImSpinner2 } from 'react-icons/im';
import Waiting from './waiting';

const Index = ({
  isLoading = false,
  button = false,
  children,
  type = 'spinner',
  size = 15
}) => {
  return (
    <div
      className={`flex justify-center ${button &&
        isLoading &&
        `px-4 md:px-12`}`}
    >
      {isLoading ? (
        type === 'spinner' ? (
          <span className="animate-spin h-4 w-4 mt-3">
            <ImSpinner2 size={size} />
          </span>
        ) : (
          <Waiting size={typeof size === 'number' ? 'md' : size} />
        )
      ) : (
        <>{children}</>
      )}
    </div>
  );
};

export default Index;
