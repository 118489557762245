import React from 'react';
import Card from './card';
import Lottie from 'react-lottie';
import * as animationData from './animation/code.json';
import * as animationData2 from './animation/graph.json';
import * as animationData3 from './animation/qr.json';

const Index = () => {
  const defaultOptionsCode = {
    loop: true,
    autoplay: true,
    animationData: animationData.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const defaultOptionsTools = {
    loop: true,
    autoplay: true,
    animationData: animationData2.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const defaultOptionsqr = {
    loop: true,
    autoplay: true,
    animationData: animationData3.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

    return (
        <div className="bgSec1 py-20 flex flex-col items-center">
            <div className="w-full lg:w-3/4 2xl:w-1/2">
                <div className="mt-10 px-5 md:px-10 lg:px-0 w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 2xl:gap-20 gap-3 md:gap-5">
                    <Card title="MilaCoins" link='/documentation/Introduction' text="Use a cutting-edge payment gateway for crypto operations." img={<Lottie
                    options={defaultOptionsTools}
                    height={100}
                    width={100}
                    isStopped={false}
                    isPaused={false}
                    />}/>
                    <Card title="Payment Tools" link='/documentation/Payments' text="Invoice customers or add a payment widget to your website." img={<Lottie
                    options={defaultOptionsqr}
                    height={100}
                    width={100}
                    isStopped={false}
                    isPaused={false}
                    />}/>
                    <Card title="API Integration" link='/documentation/APISetup' text="Integrate MilaCoins Gateway via our REST API." img={<Lottie
                    options={defaultOptionsCode}
                    height={100}
                    width={100}
                    isStopped={false}
                    isPaused={false}
                    />}/>
                </div>
            </div>
        </div>
  );
};

export default Index;
