import { RoutesOfDocs } from 'pages/documentation/navigation';

const documentationRoutes = RoutesOfDocs.map(route => route.path);

const initialState = {
  publicRoutes: [
    ...documentationRoutes,
    '/',
    '/waiting-list',
    '/contact-us',
    '/how-it-works',
    '/live-demo',
    '/login',
    '/demo-login',
    '/contact-us',
    '/create-account',
    '/email-confirmation',
    '/logout',
    '/siteMap',
    '/about-us',
    '/not-allowed-countries',
    '/FAQ',
    '/why-us',
    '/careers/apply',
    '/careers',
    '/careers/linkedIn',
    '/eCommerce',
    '/professionalsServices',
    '/ITDevelopers',
    '/charities',
    '/merchants',
    '/get-started',
    '/reset-password',
    '/forgot-password',
    '/verify-email',
    '/error-page',
    '/error-token-expired',
    '/error-page500',
    '/privacy-policy',
    '/cookies-policy',
    '/terms-of-service',
    '/aml-policy',
    '/error-timeout',
    '/pricing',
    '/pricing/fees',
    '/invoice',
    '/WidgetPayment',
    '/invoice/refund'
  ]
};

export default function routes(state = initialState) {
  return state;
}
