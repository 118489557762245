import { useCallback } from 'react';
import { useRouter } from 'myHooks';
import api from 'api';
import {
  REDIRECT,
  RETURN_MESSAGE,
  THROW,
  errorList,
  httpStatus
} from './errorList';

export default function useErrorHandler() {
  const { push: redirectTo, pathname, query, location } = useRouter();

  const sendUnknownErrorToServer = useCallback(
    (err, type) => {
      let error = {
        message: err.message,
        stack: err.stack,
        data: err.data,
        frontLocation: { pathname, query, location },
        apiReq: err.apiReq
      };

      try {
        api('/logs/unknownError', {
          method: 'POST',
          body: { ...error, type },
          captcha: true
        });
      } catch (err) {
        console.log(err);
      }
    },
    [pathname, query, location]
  );

  const actionErrorHandler = useCallback(
    error => {
      try {
        const { code = 0, message = null, data, status } = error || {},
          handle = errorList[code] || httpStatus[status] || {};

        //console.log(error, handle, 'errorHandler');

        // if (status === 403 && error.code !== 603) {
        //   redirectTo(handle.path || '/login');
        //   return { code, message: message || 'Unknown Error', data };
        // }

        if (handle.logIt) sendUnknownErrorToServer(error, 'action');

        switch (handle.action) {
          case RETURN_MESSAGE:
            return {
              code,
              data,
              message: handle.message || message || 'no message'
            };
          case REDIRECT:
            redirectTo(handle.path || '/error-page');
            return { code, message: 'redirect', data };
          case THROW:
            throw error;
          default:
            if (Number.isInteger(code) && code > 0)
              return {
                code,
                data,
                message: handle.message || message || 'no message'
              };
            // send Unknown Error log to the server
            sendUnknownErrorToServer(error, 'action');
            redirectTo('/error-page');
            return { code: 500, message: 'unknown' };
        }
      } catch (err) {
        const { code = 0 } = err,
          handle = errorList[code] || {};
        if (handle.action === THROW) throw err;

        // send Unknown Error log to the server
        sendUnknownErrorToServer(error, 'action');
        redirectTo('/error-page');
        return { code: 500, message: 'unknown' };
      }
    },
    [redirectTo, sendUnknownErrorToServer]
  );

  const reactErrorHandler = useCallback(
    error => {
      if (error?.name === 'ChunkLoadError') {
        window.location.reload();
        return;
      }
      try {
        sendUnknownErrorToServer(error, 'react');
        redirectTo('/error-page500');
      } catch (err) {
        //send Unknown Error log to the server
        sendUnknownErrorToServer(error, 'react');
        redirectTo('/error-page');
      }
    },
    [redirectTo, sendUnknownErrorToServer]
  );

  return { actionErrorHandler, reactErrorHandler };
}
