import React from 'react';
import { Link } from 'react-router-dom';

export const RIGHT = ({ children }) => {
  return <div className="flex justify-end">{children}</div>;
};

export const CENTER = ({ children }) => {
  return <div className="flex justify-center">{children}</div>;
};

export const LEFT = ({ children }) => {
  return <div className="flex justify-start">{children}</div>;
};

export const H1 = ({ children }) => {
  return (
    <div className="pl-4 py-8 mb-8 border-b-2 border-grey-200">
      <h2 className="text-grey-800 font-semibold">{children}</h2>
    </div>
  );
};

export const H4 = ({ children }) => {
  return (
    <div className="px-3 sm:px-10 pt-2">
      <h4 className="text-grey-800 font-semibold">{children}</h4>
    </div>
  );
};

export const H5 = ({ children }) => {
  return (
    <div className="px-3 sm:px-10 pt-2">
      <h5 className="text-grey-800 font-semibold">{children}</h5>
    </div>
  );
};

export const P = ({ children, size = 'lg' }) => {
  return (
    <div className={`flex flex-wrap px-3 sm:px-10 text-${size} py-2`}>
      <div className="text-grey-800">{children}</div>
    </div>
  );
};

export const TAG = ({ children, isSmall }) => {
  return (
    <span
      className={`px-1 bg-grey-100 text-grey-800 ${
        !isSmall && 'uppercase'
      } font-thin`}
    >
      {children}
    </span>
  );
};

export const LINK = ({ children, to = '', target }) => {
  const checkIfIn = to => {
    if (to.charAt(0) === '/' && target === undefined) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      {checkIfIn(to) ? (
        <Link to={to}>
          <span className="link text-sm sm:text-lg break-words">
            {children}
          </span>
        </Link>
      ) : (
        <a
          className="link text-sm sm:text-lg break-words"
          href={to}
          target={target}
        >
          {children}
        </a>
      )}
    </>
  );
};

export const UL = ({ children }) => {
  return <div className="sm:px-10 pt-2">{children}</div>;
};

export const LI = ({ children }) => {
  return (
    <div className="flex flex-row py-1">
      <p className="text-grey-800 text-lg normal-case">
        <span className="mr-2 text-xl">•</span>
        {children}
      </p>
    </div>
  );
};

export const BR = () => {
  return <div className="mb-10"></div>;
};

export const STRONG = ({ children }) => {
  return <span className="font-bold mx-1">{children}</span>;
};

export const COLOR = ({ children, color }) => {
  return <span className={`text-${color}-400`}>{children}</span>;
};

export const NEXTEACHOTER = ({ children }) => {
  return <div className="flex flex-row space-x-2">{children}</div>;
};

export const COL = ({ children }) => {
  return <div className="flex w-full flex-col space-y-1">{children}</div>;
};

export const TAB = ({ children }) => {
  return <div className="pl-4">{children}</div>;
};
