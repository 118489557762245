import React from 'react';
import { MdNavigateNext } from 'react-icons/md';
import { Link } from 'react-router-dom';

const Index = () => {
  return (
    <div className="w-full bg-headerDoc h-75vh xl:h-60vh">
      <div className="lg:w-3/4 relative">
        <div className="homepage-header-sm lg:hidden  h-75vh xl:h-60vh absolute top-0 left-0 z-20">
          <div className="flex flex-col h-full w-full items-center justify-center">
            <div className="w-10/12 md:w-7/12 flex flex-col leading-none px-4 sm:px-8 md:px-0">
              <h1 className="text-mila-400 text-4xl sm:text-5xl hpBoldFont capitalize">
                Extra Power
              </h1>
              <h1 className="text-white flex flex-wrap text-4xl sm:text-5xl hpBoldFont capitalize">
                Over Your Funds<span className="ml-5"></span>
              </h1>
              <h6 className="text-white mt-2 text-sm lg:text-2xl hpRegFont normal-case md:w-3/4 leading-normal">
                Get unparalleled benefits both for your company and for your
                customers with a set of payment tools offered by MilaCoins.
              </h6>
              <div className="flex w-full space-y-3 sm:space-y-0 flex-col sm:flex-row sm:space-x-3 mt-4">
                <Link to="/documentation/Introduction">
                  <button className="btn p-4 btn-default bg-mila-400 hover:bg-mila-700 text-white border-mila-400 btn-rounded">
                    <span className="text-base justify-center flex space-x-2">
                      Explore <MdNavigateNext />
                    </span>
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="hidden lg:block  h-75vh xl:h-60vh homepage-header-docs absolute top-0 left-0 z-0">
          <div className="flex flex-col  h-75vh xl:h-60vh w-full pl-20 2xl:pl-24 justify-center">
            <div className="w-8/12 flex flex-col leading-none">
              <h1 className="text-mila-400 md:text-6xl xl:text-7xl hpBoldFont">
                Extra Power
              </h1>
              <h1 className="text-white flex flex-wrap md:text-6xl xl:text-7xl hpBoldFont">
                Over Your Funds<span className="ml-5"></span>
              </h1>
              <h6 className="text-white mt-2 text-xl normal-case hpRegFont w-3/4 leading-normal">
                Get unparalleled benefits both for your company and for your
                customers with a set of payment tools offered by MilaCoins.
              </h6>
              <div className="flex flex-col md:flex-row md:space-x-3 mt-4">
                <Link to="/documentation/Introduction">
                  <button className="btn p-4 btn-default bg-mila-400 hover:bg-mila-700 text-white border-mila-400 btn-rounded">
                    <span className="text-xl flex space-x-2">
                      Explore <MdNavigateNext />
                    </span>
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="homepage-header-back-sm lg:hidden  h-75vh xl:h-60vh w-full z-10 lg:ml-5 2xl:ml-0 absolute top-0 left-0"></div>
        <div className="hidden lg:block homepage-header-docs-back w-full z-10 absolute top-0 left-0"></div>
      </div>
      <div className="w-1/2"></div>
    </div>
  );
};

export default Index;
