import React, { useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { FiMenu } from 'react-icons/fi';
import { navBar } from '../navigation';
import { Link } from 'react-router-dom';
import 'css/components/navbar.css';
import Logo from './logo';

const Navbar = ({ isPreLender }) => {
  const [isSmallSearch] = useState(false);
  const { config } = useSelector(
    state => ({
      config: state.config,
      isVerify: state?.user?.company?.kyb?.status !== 'unverified'
    }),
    shallowEqual
  );
  let { collapsed } = { ...config };
  const dispatch = useDispatch();
  return (
    <>
      <div className="navbar navbar-1 fixed z-30 w-full right-0 border-b pr-10 p-2">
        <div className="flex">
          <div className="flex flex-col w-full">
            <div className="navbar-inner w-full flex items-center justify-start">
              <div className="flex space-x-1 sm:space-x-5">
                <button
                  onClick={() => {
                    dispatch({
                      type: 'SET_CONFIG_KEY',
                      key: 'collapsed',
                      value: !collapsed
                    });
                  }}
                  className="mx-4 md:hidden"
                >
                  <FiMenu size={20} />
                </button>
                {isPreLender && (
                  <span>
                    <Logo />
                  </span>
                )}
              </div>

              {isPreLender ? (
                <></>
              ) : (
                <>
                  {/* <div className="hidden md:flex">
                    <Search
                      isSmallSearch={isSmallSearch}
                      setIsSmallSearch={setIsSmallSearch}
                    />
                  </div> */}
                  <div className="md:hidden">
                    <Logo />
                  </div>
                </>
              )}
              <span className="ml-auto"></span>
              <div className="hidden justify-start items-center md:flex flex-row space-x-5">
                {navBar[0].items.map((nav, key) => {
                  return (
                    <Link key={key} to={nav.url}>
                      {nav.title !== 'SignUp' ? (
                        <p className="hpRegFont text-base px-5 transform transition duration-300 hover:scale-105 ease-in-out hover:text-mila-300">
                          {nav.title}
                        </p>
                      ) : (
                        <div className="hpRegFont text-base px-5 py-2 btn-outlined bg-transparent transform transition duration-300 hover:scale-105 ease-in-out text-mila-300 border rounded border-mila-300 hover:text-mila-300 hover:bg-grey-100">
                          {nav.title}
                        </div>
                      )}
                    </Link>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      {isSmallSearch && (
        <div
          className={`w-full ${
            isSmallSearch ? 'animate-apperBottom' : ''
          } py-2 bg-grey-300 dark:bg-grey-800`}
        >
          <form className="ml-2">
            <div className="flex justify-center">
              <div className=" w-11/12 relative">
                <input
                  type="search"
                  name="search"
                  placeholder="Search..."
                  className="pl-10 pr-5 appearance-none h-10 w-full rounded-full text-sm focus:outline-none"
                />
              </div>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default Navbar;
