import React, { useEffect } from "react";
import { authActions } from "actions";
import Loading from "components/widgets/loading";
import { useAsyncAction } from "myHooks";
import { envMode } from "api";

const push = (url) => {
  setTimeout(function () {
    window.location = url;
  }, 100);
};

const Index = ({ children }) => {
  const { status, execute, isLoading, error, value } = useAsyncAction(
    authActions.loginDemoAccount
  );

  useEffect(() => {
    if (status === "success" && value) {
      push(value);
    }
  }, [status, value]);

  return (
    <div>
      {error && (
        <p className="text-red-500 hpRegFont text-sm 2xl:text-base my-1">
          {error.message}
        </p>
      )}
      <Loading isLoading={isLoading}>
        {envMode === "sandbox" ? (
          <div onClick={() => execute()}>{children}</div>
        ) : (
          <a href={"https://sandbox.milacoins.com/demo-login"} rel="noreferrer">
            {children}
          </a>
        )}
      </Loading>
    </div>
  );
};

export default Index;
