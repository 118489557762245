import React from 'react';
import Item from './item';
import Logo from './logo';
import 'css/components/tooltips.css';
import './styles-sm-doc.css';
import './styles-lg-doc.css';
import { leftSideNavigation, navBar } from '../navigation';

const Sidebar = ({ isPreLander }) => {
  return (
    <div className="left-sidebar relative z-40 h-full left-sidebar-1">
      <div className="py-4">
        <Logo />
      </div>
      {isPreLander ? (
        <></>
      ) : (
        leftSideNavigation &&
        leftSideNavigation.map((menu, i) => (
          <>
            {menu.title && (
              <div key={`${menu.title}-${i}`} className="left-sidebar-title">
                <span>{menu.title} </span>
              </div>
            )}
            <ul>
              {menu.items.map((l0, a) => (
                <li key={`${a}-menu`} className="text-grey-500 font-extralight">
                  <Item {...l0} />
                  <ul>
                    {l0.items.map((l1, b) => (
                      <li key={`${a}-${b}-l0`} className="text-grey-400 ml-5">
                        <Item {...l1} />
                        <ul>
                          {l1.items.map((l2, c) => (
                            <li key={`${a}-${b}-${c}-l1`} className="l2 ml-4">
                              <Item {...l2} />
                              <ul>
                                {l2.items.map((l3, d) => (
                                  <li
                                    key={`${a}-${b}-${c}-${d}-l2`}
                                    className="l3"
                                  >
                                    <Item {...l3} />
                                    <ul>
                                      {l3.items.map((l4, e) => (
                                        <li
                                          key={`${a}-${b}-${c}-${d}-${e}-l3`}
                                          className="l4"
                                        >
                                          <Item {...l4} />
                                        </li>
                                      ))}
                                    </ul>
                                  </li>
                                ))}
                              </ul>
                            </li>
                          ))}
                        </ul>
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>
          </>
        ))
      )}
      <div className="lg:hidden">
        {navBar &&
          navBar.map((menu, i) => (
            <>
              {menu.title && (
                <div
                  key={`${menu.title}-${i}`}
                  className="left-sidebar-title-doc"
                >
                  <span>{menu.title} </span>
                </div>
              )}
              <ul>
                {menu.items.map((l0, a) => (
                  <li
                    key={`${a}-menu`}
                    className="text-grey-500 flex flex-wrap font-extralight"
                  >
                    <Item {...l0} />
                    <ul>
                      {l0.items.map((l1, b) => (
                        <li key={`${a}-${b}-l0`} className="text-grey-400 ml-5">
                          <Item {...l1} />
                          <ul>
                            {l1.items.map((l2, c) => (
                              <li key={`${a}-${b}-${c}-l1`} className="l2">
                                <Item {...l2} />
                                <ul>
                                  {l2.items.map((l3, d) => (
                                    <li
                                      key={`${a}-${b}-${c}-${d}-l2`}
                                      className="l3"
                                    >
                                      <Item {...l3} />
                                      <ul>
                                        {l3.items.map((l4, e) => (
                                          <li
                                            key={`${a}-${b}-${c}-${d}-${e}-l3`}
                                            className="l4"
                                          >
                                            <Item {...l4} />
                                          </li>
                                        ))}
                                      </ul>
                                    </li>
                                  ))}
                                </ul>
                              </li>
                            ))}
                          </ul>
                        </li>
                      ))}
                    </ul>
                  </li>
                ))}
              </ul>
            </>
          ))}
      </div>
    </div>
  );
};

export default Sidebar;
