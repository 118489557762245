import React from 'react';
import PAGE from '../../../components/page';
import { H1, P, UL, LI, BR, LINK } from '../../../components';

const Index = () => {
  return (
    <PAGE>
      <H1>Payment Tools</H1>
      <P>
        Using MilaCoins cryptocurrency payment gateway, businesses can accept cryptocurrencies as payment for goods or services. MilaCoins offers the following payment tools:
      </P>
      <UL>
        <LI>
          <LINK to="/documentation/Payments/WidgetPayment">Payment widget</LINK>
        </LI>
        <P>
          Easily integrate MilaCoins Payment Widget into your website to make a
          secure checkout for your customers and accept crypto payments.
        </P>
        <LI>
          <LINK to="/documentation/Payments/URLPayment">
            Payment Link Generator
          </LINK>
        </LI>
        <P>
        enerate payment links with a few taps and send them to your customers via Email, SMS, Telegram, etc.
        </P>
        <LI>
          <LINK to="/documentation/Payments/Donate">Donation Option</LINK>
        </LI>
        <P>
          Meet the purpose of your charity faster by allowing crypto paying
          donors to make donations, either using a payment widget or by payment
          link generator.
        </P>
      </UL>
      <BR />
    </PAGE>
  );
};

export default Index;
