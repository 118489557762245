import React from 'react'

export const ModalFooter = ({onClick,rander}) => (
  <div className="modal-footer space-x-2">
      {rander}
  </div>
)
/*
<button
      className="btn btn-default btn-rounded bg-blue-500 hover:bg-blue-600 text-white"
      type="button"
      onClick={onClick}>
      Close
    </button>
    <button
      className="btn btn-default btn-rounded bg-blue-500 hover:bg-blue-600 text-white"
      type="button"
      onClick={onClick}>
      Save Changes
    </button>*/