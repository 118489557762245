import axios from 'axios';
import getCaptchaToken from './reCaptcha';
const baseURL = '/api/v1',
  SUCCESS = 'success';

const callApi = axios.create({
  baseURL,
  timeout: 40000
});

async function callTfa({ tfa, method, endpoint, headers, body, params }) {
  return await callApi({
    method: 'POST',
    url: '/2fa',
    headers,
    data: {
      type: tfa,
      url: `${baseURL}${endpoint}`,
      method,
      body,
      params
    }
  });
}

export default async function api(
  endpoint,
  { method = 'GET', body, params, fileToUpload, tfa, captcha, headers } = {
    method: 'GET',
    body: {},
    params: {},
    fileToUpload: false,
    tfa: false,
    captcha: false,
    headers: {}
  }
) {
  let _headers = headers || {};
  if (fileToUpload) _headers['Content-Type'] = 'multipart/form-data';

  if (captcha || tfa) _headers['captcha-token'] = await getCaptchaToken();

  try {
    let res = {};

    if (tfa) {
      res = await callTfa({
        tfa,
        method,
        endpoint,
        headers: _headers,
        body,
        params
      });
    } else {
      res = await callApi({
        method,
        url: endpoint,
        headers: _headers,
        data: body,
        params
      });
    }

    const { data = {} } = res;

    if (data?.status === SUCCESS) {
      if (tfa) return { data: data.data, tfa: true };
      else return data.data;
    } else {
      const error = {
        ...data,
        apiReq: {
          method: method,
          url: endpoint,
          headers: _headers,
          data: body,
          params,
          tfa
        }
      };
      throw error;
    }
  } catch (err) {
    const { data = err, status } = err?.response || {},
      error = {
        ...data,
        status,
        tfa,
        apiReq: {
          method: method,
          url: endpoint,
          headers: _headers,
          data: body,
          params
        }
      };
    if (!error.data) error.data = err;
    throw error;
  }
}

let envMode = 'prod';
if (window.location.origin.includes('sandbox')) envMode = 'sandbox';
else if (window.location.origin.includes('test')) envMode = 'test';
else if (window.location.origin.includes('localhost')) envMode = 'test';

export { envMode };

export const envPath = `${window.location.origin}${window.location.pathname}`;
export const envOrigin = `${window.location.origin}`;
