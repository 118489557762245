import api from "api";

export const LOGIN = "LOGIN",
  LOGOUT = "LOGOUT",
  SET_EXPIRE_TIME = "SET_EXPIRE_TIME",
  SET_SMS_AUTH = "SET_SMS_AUTH";

export const Login = (loginData) => {
  return async (dispatch) => {
    const data = await api("/user/login", {
      method: "POST",
      body: loginData,
      captcha: true,
    });
    return data.redirectTo || "/backoffice/dashboard";
  };
};

export const loginDemoAccount = () => {
  return async (dispatch) => {
    const data = await api("/user/loginDemo", {
      method: "POST",
      captcha: true,
    });
    return data.redirectTo || "/backoffice/dashboard";
  };
};

export const logout = () => {
  return async (dispatch) => {
    await api("/user/logout");
  };
};

export const createCompany = (data) => {
  return async () => {
    const { companyname, email, password } = data;
    await api("/company", {
      method: "POST",
      body: {
        name: companyname,
        root: {
          email: email,
          password: password,
          passwordConfirm: password,
        },
      },
      captcha: true,
    });
    //await dispatch({ type: LOGOUT });
  };
};

export const reSendVerifyEmail = (email) => {
  /// add charter and capital to password
  return async () => {
    await api(`/user/resendVerifyEmail`, {
      method: "POST",
      body: { email: email },
      captcha: true,
    });
    return;
  };
};

export const verifyEmail = (token) => {
  return async () => {
    const { userInfo } = await api(`/user/verifyEmail/${token}`);

    if (userInfo.role === "root") {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "sign_up",
        userId: `${userInfo._id}`,
      });
    }
    return userInfo.role;
  };
};

export const sendResetPassword = (data) => {
  return async () => {
    const body = data;
    if (data.type === "sms") body.phoneNumber = `+${data.prefix}${data.phone}`;
    const res = await api(`/user/security/resetPassword`, {
      method: "POST",
      body,
      captcha: true,
    });
    return res;
  };
};

export const updatePasswordByTokens = (data) => {
  return async () => {
    const { redirectTo } = await api(`/user/security/updatePasswordByTokens`, {
      method: "PATCH",
      body: data,
      captcha: true,
    });
    return redirectTo || "/backoffice/dashboard";
  };
};

export const enableNewDevice = (data) => {
  return async () => {
    const { redirectTo } = await api(`/user/security/enableNewDevice`, {
      method: "PATCH",
      body: data,
      captcha: true,
    });
    return redirectTo || "/backoffice/dashboard";
  };
};
