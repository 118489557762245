import React from 'react';
import { H1, P, H4, UL, LI, STRONG, BR, LINK, H5 } from '../../../components';
import { TABLE, THEAD, TH, TBODY, TD, TR } from '../../../components/table';
import PAGE from '../../../components/page';
import { SHARPIMAGE } from '../../../components/images';
//import { CODEBLOCK } from '../../../components/code';
import img1 from './img1.png';
import img3 from './img3.png';
import { CODEBLOCK } from '../../../components/code';

const Index = () => {
  return (
    <PAGE>
      <H1>Webhooks</H1>
      <P>
        Webhooks are HTTP POST requests sent to specific targets in JSON format.
      </P>
      <BR />
      <BR />
      <P>
        * Please note that https is the only way we send information to
        endpoints; http is not used.
      </P>
      <BR />
      <H4>Steps overview:</H4>
      <P>
        <UL>
          <P>Step 1. Create new webhook on the backoffice.</P>
          <P>Step 2. Set up your http server to serve the target.</P>
          <P>Step 3. Make test request.</P>
          <P>Step 4. Verifying the request.</P>
        </UL>
      </P>
      <BR />
      <BR />
      <H4>Create new webhook:</H4>
      <UL>
        <LI>
          <STRONG>
            Log in to your account: <LINK to="/login">Login</LINK>
          </STRONG>
          .
        </LI>
        <LI>
          Navigate to{' '}
          <LINK to="/backoffice/settings/webhooks" target={'_blank'}>
            Setting webhooks
          </LINK>{' '}
          : <STRONG>Setting</STRONG>
          {'>'}
          <STRONG>Webhooks</STRONG>.
        </LI>
        <LI>Create webhook .</LI>
      </UL>
      <BR />
      <BR />
      <H5>Webhooks types</H5>
      <UL>
        <LI>
          <STRONG>Invoice status changed</STRONG>
        </LI>
        Payload attributes:
        <TABLE>
          <THEAD>
            <TH size="1/3">
              <STRONG>Field</STRONG>
            </TH>
            <TH size="1/3">
              <STRONG>Type</STRONG>
            </TH>
            <TH size="1/3">
              <STRONG>Description</STRONG>
            </TH>
          </THEAD>
          <TBODY>
            <TR>
              <TD size="1/3">id</TD>
              <TD size="1/3">String</TD>
              <TD size="1/3">Invoice id</TD>
            </TR>
            <TR>
              <TD size="1/3">status</TD>
              <TD size="1/3">String</TD>
              <TD size="1/3">
                Invoice status changed to, options:{' '}
                <code>
                  completed, invalid, waitingForConfirmation, underPaid,
                  overPaid, paidLate
                </code>
              </TD>
            </TR>
            <TR>
              <TD size="1/3">externalID</TD>
              <TD size="1/3">String</TD>
              <TD size="1/3">
                Contains unique record invoice identifiers from your system
              </TD>
            </TR>
          </TBODY>
        </TABLE>
      </UL>
      <BR />
      <BR />
      <BR />
      <BR />
      <H4>Testing webhook</H4>
      <UL>
        <LI>Use "RUN TEST" button to send test request.</LI>
        <SHARPIMAGE>{img1}</SHARPIMAGE>
        <SHARPIMAGE>{img3}</SHARPIMAGE>
      </UL>
      <BR />
      <BR />
      <BR />
      <BR />
      <H4>Verify Payload</H4>
      <P>
        To ensure that webhooks are sent by us, By using the HMAC sha256
        algorithm, we sign the body with your secret key.{' '}
      </P>
      <UL>
        <STRONG>Steps in the verification process:</STRONG>
        <LI>
          Get a webhook <code>X-Payload-Sig </code> header value.
        </LI>
        <LI>Receive the HTTP webhook body in bytes.</LI>
        <LI>
          Calculate a digest using the Secret Key, the raw webhook body in
          bytes, and the HMAC sha256 algorithm
        </LI>
        <LI>
          Compare <code>X-Payload-Sig </code> header value with calculated
          digest.
        </LI>
      </UL>{' '}
      <BR />
      <STRONG>Computing digest function ( Node JS example)</STRONG>
      <CODEBLOCK language="js">{`const crypto = require('crypto')
function verifyPayload(req, secret) {
  const calculatedDigest = crypto
    .createHmac('sha256', secret)
    .update(req.rawBody)
    .digest('hex');

  return calculatedDigest === req.get('X-Payload-Sig');
}
 `}</CODEBLOCK>
    </PAGE>
  );
};

export default Index;
