import React from 'react';
import PAGE from '../../../../components/page';
import {
  H1,
  P,
  H4,
  STRONG,
  LINK,
  BR,
  UL,
  LI,
  TAB
} from '../../../../components';
import { SHARPIMAGE } from '../../../../components/images';
import img2 from './woocommerce-addplugin.png';
import img4 from './woocommerce-widget.png';
import img5 from './woocommerce-wallettarget.png';
import img6 from './woocommerce-mode.png';
import img7 from './woocommerce-style.png';
import img8 from './woocommerce-enabled.png';

const Index = () => {
  return (
    <PAGE>
      <H1>WooCommerce</H1>
      <P>
        The WooCommerce plugin from MilaCoins allows you to accept payments in a
        wide variety of crypto currencies and get the funds automatically
        exchanged into your preferred currency.
      </P>
      <P>
        <STRONG>
          Follow the steps below to add the MilaCoins plugin to your store.
        </STRONG>
      </P>
      <UL>
        <LI>Copy the widget key from your MilaCoins account.</LI>
        <LI>
          Install and activate the MilaCoins plugin on your WordPress system.
        </LI>
        <LI>Setup MilaCoins as a payment method for your WooCommerce Store.</LI>
      </UL>

      <BR />
      <BR />

      <H4>Get the Widget Key</H4>
      <UL>
        <LI>
          <LINK to="https://milacoins.com/login" target={'_blank'}>
            Log in
          </LINK>{' '}
          to your MilaCoins account -{' '}
          <LINK to="https://milacoins.com/create-account">click here </LINK> if
          you do not have one.
        </LI>
        <LI>
          Navigate To{' '}
          <LINK
            to="https://milacoins.com/backoffice/payments-tools/plugins/woocommerce"
            target={'_blank'}
          >
            WooConmmerce plugin
          </LINK>
          : <STRONG>Payment Tools</STRONG> {'>'}{' '}
          <STRONG>Setup Payment Button</STRONG> {'>'}{' '}
          <STRONG>WooCommerce.</STRONG>
        </LI>
        <LI>Copy the Widget Key.</LI>
      </UL>
      <SHARPIMAGE>{img4}</SHARPIMAGE>

      <BR />
      <BR />
      <BR />
      <BR />

      <H4>Install and activate the plugin</H4>
      <UL>
        <LI>
          Go to the Wordpress dashboard {'>'} <STRONG>Plugins</STRONG> {'>'}{' '}
          <STRONG>Add New</STRONG> {'>'} search for “MilaCoins”.
        </LI>
        <LI>
          As a top result, you should see the "Payment Gateway through MilaCoins
          on WooCommerce", click <STRONG>Install now.</STRONG>
        </LI>
        <LI>
          Once the installation is complete, click <STRONG>Activate.</STRONG>
        </LI>
      </UL>
      <SHARPIMAGE>{img2}</SHARPIMAGE>

      <BR />
      <BR />
      <BR />
      <BR />

      <H4>Add MilaCoins as a payment method to your WooCommerce Store</H4>
      <UL>
        <LI>
          Within the WordPress administration area, go to the{' '}
          <STRONG>WooCommerce</STRONG> {'>'} <STRONG>Settings</STRONG> {'>'}{' '}
          <STRONG>Payments</STRONG> and you will see MilaCoins in the list of
          available payment gateways.
        </LI>
        <LI>
          Press on the <STRONG>Manage (or Setup).</STRONG>
        </LI>
        <LI>
          Configure the payment method:
          <TAB>
            <STRONG>1. Widget Key:</STRONG> copy the widget key from your
            MilaCoins account. To copy the key, navigate to the{' '}
            <STRONG>Payment Tools</STRONG>
            section within MilaCoins account.
            <br /> Payment tools {'>'}Setup Payment button {'>'} WooCommerce
            plugin.
            <SHARPIMAGE>{img4}</SHARPIMAGE>
            <BR />
            <STRONG>2. Target wallet:</STRONG> Choose the wallet (currency) for
            receiving your automatically exchanged payments.
            <SHARPIMAGE>{img5}</SHARPIMAGE>
            <BR />
            <STRONG>3. Mode:</STRONG> Choose "Sandbox" mode for the testing
            environment and "Live" mode for the real (production) environment.
            <SHARPIMAGE>{img6}</SHARPIMAGE>
            <STRONG>Note:</STRONG> If you are using Sandbox widget key
            credentials, turn Sandbox (Test Mode) on. Key widget credentials
            generated in MilaCoins{' '}
            <LINK to="https://milacoins.com/">
              real production environment (live)
            </LINK>{' '}
            will not work for “Sandbox” mode. Generate separate widget key
            credentials for the{' '}
            <LINK to="https://sandbox.milacoins.com/">“Sandbox”</LINK> mode.
            Read more about <LINK to="/documentation/Testing">testing</LINK>{' '}
            MilaCoins.
            <BR />
            <STRONG>4. Customize Payment buttons:</STRONG> Select the style of
            your payment buttons to match your website design.
            <SHARPIMAGE>{img7}</SHARPIMAGE>
            <BR />
            <STRONG>5. Enable/Disable options:</STRONG> make sure the payment
            method is enabled.
            <SHARPIMAGE>{img8}</SHARPIMAGE>
            <BR />
            <STRONG>6. Save your settings:</STRONG> Finally, click “Save
            changes”, and you are ready to start! Once you’re done with these
            steps, you will be able to start accepting crypto payments directly
            from your site.
          </TAB>
        </LI>
      </UL>
      <BR />
    </PAGE>
  );
};

export default Index;
