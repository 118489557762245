import React from 'react';
import { H1, P } from '../../../components';
import PAGE from '../../../components/page';

const Index = () => {
  return (
    <PAGE>
      <H1>Wallet</H1>
      <P>
      MilaCoins wallets allow our users to receive payments from their customers. Also, they can use these wallets to send, exchange, and store the received funds. 
      </P>
    </PAGE>
  );
};

export default Index;
