import React from 'react';
import { H1, P, H4, STRONG, TAG, COL, BR, UL, LI } from '../../../components';
import { CODEBLOCK } from '../../../components/code';
import PAGE from '../../../components/page';
import { TABLE, THEAD, TH, TBODY, TD, TR } from '../../../components/table';
import {
  MILABTN,
  SECONDARYBTN,
  BLACKBTN,
  WHITEBTN,
  SHARPBTN,
  ROUNDEDFULLBTN
} from '../../../components/widgetBtns';
import { useWindowSize } from 'myHooks';

const Index = () => {
  const { width: windowW } = useWindowSize();

  return (
    <PAGE>
      <H1>Customize the MilaCoins Button</H1>
      <P>A Payment Button can be styled in several different ways.</P>
      <BR />
      <CODEBLOCK language="javascript">
        {`milacoins.button({
  style: {
    width: '100%',
    height: '45px',
    color: 'mila',
    buttonStyle: 'rounded'
  }
})`}
      </CODEBLOCK>

      <BR />
      <H4>Color</H4>
      <TABLE>
        {windowW > 768 ? (
          <>
            <THEAD>
              <TH size="2/12">
                <STRONG>Value</STRONG>
              </TH>
              <TH size="5/12">
                <STRONG>Description</STRONG>
              </TH>
              <TH size="5/12">
                <STRONG>Button</STRONG>
              </TH>
            </THEAD>
            <TBODY>
              <TR>
                <TD size="2/12">
                  <TAG isSmall="true">mila</TAG>
                </TD>
                <TD size="5/12">
                  <COL>
                    <P size="base">
                      <STRONG>Recommended</STRONG>
                    </P>
                    <P size="base">
                      The most popular button color among MilaCoins users.
                    </P>
                  </COL>
                </TD>
                <TD size="5/12">
                  <MILABTN />
                </TD>
              </TR>

              <TR>
                <TD size="2/12">
                  <TAG isSmall="true">secondary</TAG>
                </TD>
                <TD size="5/12">
                  <COL>
                    <P size="base">
                      <STRONG>First alternative</STRONG>
                    </P>
                    <P size="base">
                      You can try the "secondary" MilaCoins button if the "mila"
                      (gradient) color doesn't feel right for your site.
                    </P>
                  </COL>
                </TD>
                <TD size="5/12">
                  <SECONDARYBTN />
                </TD>
              </TR>
              <TR>
                <TD size="2/12">
                  <COL>
                    <TAG isSmall="true">black</TAG>
                    <TAG isSmall="true">white</TAG>
                  </COL>
                </TD>
                <TD size="5/12">
                  <COL>
                    <P size="base">
                      <STRONG>Second alternative </STRONG>
                    </P>
                    <P size="base">
                      Try white or black buttons instead of "mila" or
                      "secondary" buttons if they don't complement your site's
                      design or aesthetics. These colors, though, are less
                      likely to draw attention, so they are less recommended.
                    </P>
                  </COL>
                </TD>
                <TD size="5/12">
                  <COL>
                    <BLACKBTN />
                    <WHITEBTN />
                  </COL>
                </TD>
              </TR>
            </TBODY>
          </>
        ) : (
          <>
            <THEAD>
              <TH size="4/12">
                <STRONG>Value</STRONG>
              </TH>
              <TH size="8/12">
                <STRONG>Description</STRONG>
              </TH>
            </THEAD>
            <TBODY>
              <TR>
                <TD size="4/12">
                  <TAG isSmall="true">mila</TAG>
                </TD>
                <TD size="8/12">
                  <COL>
                    <P size="base">
                      <STRONG>Recommended</STRONG>
                    </P>
                    <P size="base">
                      We are most recognized for this color throughout the
                      world.
                    </P>
                  </COL>
                </TD>
              </TR>
              <TR size="full">
                <MILABTN />
              </TR>

              <TR>
                <TD size="4/12">
                  <TAG isSmall="true">secondary</TAG>
                </TD>
                <TD size="8/12">
                  <COL>
                    <P size="base">
                      <STRONG>First alternative</STRONG>
                    </P>
                    <P size="base">
                      You can try the "secondary" MilaCoins button if "mila"
                      color doesn't work for your site.
                    </P>
                  </COL>
                </TD>
              </TR>
              <TR size="full">
                <SECONDARYBTN />
              </TR>
              <TR>
                <TD size="4/12">
                  <COL>
                    <TAG isSmall="true">black</TAG>
                    <TAG isSmall="true">white</TAG>
                  </COL>
                </TD>
                <TD size="8/12">
                  <COL>
                    <P size="base">
                      <STRONG>Second alternative </STRONG>
                    </P>
                    <P size="base">
                      Try white or black buttons instead of "mila" or
                      "secondary" buttons if they don't complement your site's
                      design or aesthetics. These colors, though, are less
                      likely to draw attention, so they are less recommended.
                    </P>
                  </COL>
                </TD>
              </TR>
              <TR size="full">
                <COL>
                  <BLACKBTN />
                  <WHITEBTN />
                </COL>
              </TR>
            </TBODY>
          </>
        )}
      </TABLE>
      <BR />
      <H4>Button Style</H4>
      <TABLE>
        <THEAD>
          <TH size="4/12">
            <STRONG>Value</STRONG>
          </TH>
          <TH size="8/12">
            <STRONG>Description</STRONG>
          </TH>
        </THEAD>
        <TBODY>
          <TR>
            <TD size="4/12">
              <TAG isSmall="true">rounded</TAG>
            </TD>
            <TD size="8/12">
              <COL>
                <P size="base">
                  <STRONG>Recommended</STRONG>
                </P>
                <P size="base">The default button style.</P>
              </COL>
            </TD>
          </TR>
          <TR size="full">
            <MILABTN />
          </TR>

          <TR>
            <TD size="4/12">
              <TAG isSmall="true">sharp</TAG>
            </TD>
            <TD size="8/12">
              <COL>
                <P size="base">
                  <STRONG>First alternative</STRONG>
                </P>
                <P size="base">The second button style option.</P>
              </COL>
            </TD>
          </TR>
          <TR size="full">
            <SHARPBTN />
          </TR>
          <TR>
            <TD size="4/12">
              <TAG isSmall="true">roundedFull</TAG>
            </TD>
            <TD size="8/12">
              <COL>
                <P size="base">
                  <STRONG>Second alternative </STRONG>
                </P>
                <P size="base">The third button style option.</P>
              </COL>
            </TD>
          </TR>
          <TR size="full">
            <ROUNDEDFULLBTN />
          </TR>
        </TBODY>
      </TABLE>
      <BR />
      <H4>Size</H4>
      <P>By default, the button adapts to the size of its container element.</P>
      <UL>
        <LI>
          To customize the button <STRONG>width</STRONG>, alter the width of the
          container element.
        </LI>
        <LI>
          To customize the button <STRONG>height</STRONG>, set the style height
          option to a value from 25 to 55.
        </LI>
      </UL>
      <BR />
      <BR />
    </PAGE>
  );
};

export default Index;
