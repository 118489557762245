import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { envMode } from 'api';
import Alert from 'components/alerts/sandbox-widget';
import NotAllowedCountyAlert from 'components/alerts/notAllowedCountry';

const Index = () => {
  const [showBar, setShowBar] = useState(localStorage.getItem('cookiesPolicy'));
  return (
    <>
      {!showBar ? (
        <div className="fixed bottom-0 w-full z-50">
          <NotAllowedCountyAlert />
          {envMode !== 'prod' && <Alert link={true} />}
          <div className="animate-fadeInApperDownUp bg-opacity-90 bg-mila-402 pt-5 py-8  text-white w-full">
            <div className="flex flex-col space-y-6 sm:space-y-0 sm:flex-row w-full items-center px-5 lg:pl-10 lg:pr-32">
              <div className="flex flex-col w-full md:w-2/3">
                <p className="text-lg hpRegFont font-bold">Cookies</p>
                <div className="flex flex-col md:flex-row md:space-x-10 space-y-5 md:space-y-0">
                  <span className="flex flex-wrap">
                    <p className="text-base hpRegFont">
                      MilaCoins uses cookies to improve the user experience,
                      analyze traffic, and for marketing purposes. You can view
                      our Cookie Policy
                      <Link
                        to="/cookies-policy"
                        className="underline ml-2 text-grey-100"
                      >
                        read more
                      </Link>
                    </p>
                  </span>
                  <button
                    onClick={() => {
                      localStorage.setItem('cookiesPolicy', true);
                      setShowBar(true);
                    }}
                    className="btn p-4 btn-default bg-mila-999 hover:bg-grey-700 text-white text-base btn-rounded"
                  >
                    UNDERSTOOD
                  </button>
                </div>
              </div>

              <div className="w-full md:w-1/4 flex space-x-3 md:justify-end"></div>
            </div>
          </div>
        </div>
      ) : (
        <div className="fixed bottom-0 w-full nimate-fadeInApperDownUp z-50">
          <NotAllowedCountyAlert />
          {envMode !== 'prod' && <Alert link={true} />}
        </div>
      )}
    </>
  );
};

export default Index;
