import React from 'react';

export const TABLE = ({ children, doc = true }) => {
  return (
    <div className={`flex flex-col ${doc && 'px-2 md:px-10'} py-2`}>
      {children}
    </div>
  );
};

export const THEAD = ({ children }) => {
  return <div className="flex border-b-2 border-grey-200">{children}</div>;
};

export const TH = ({ size, children, doc = true }) => {
  return (
    <div className={`flex flex-col p-1 w-${size}`}>
      <div className={`text-lg ${doc && 'text-grey-500'}`}>{children}</div>
    </div>
  );
};

export const TBODY = ({ children }) => {
  return <div>{children}</div>;
};

export const TR = ({ children }) => {
  return <div className="flex border-b-2 border-grey-100">{children}</div>;
};

export const TD = ({ size, children }) => {
  return (
    <div className={`flex flex-col p-1 w-${size}`}>
      <div className="text-grey-800">{children}</div>
    </div>
  );
};
