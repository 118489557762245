import { useRef, useState, useEffect } from 'react';

function useOnScreen() {
  const ref = useRef(null);
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = new IntersectionObserver(([entry]) =>
    setIntersecting(entry.isIntersecting)
  );

  useEffect(() => {
    observer.observe(ref.current);
    // Remove the observer as soon as the component is unmounted
    return () => {
      observer.disconnect();
    };
  }, [observer, ref]);

  return [ref, isIntersecting];
}

export default useOnScreen;
