import React from 'react';
import { H1, P, H4, STRONG, LI, UL } from '../../../components';
import PAGE from '../../../components/page';
import { SHARPIMAGE, ROUNDIMAGE } from '../../../components/images';
import addPorject from './addPorject.png';
import addnewProject from './addnewProject.png';

const Index = () => {
  return (
    <PAGE>
      <H1>Project Setup</H1>
      <P>
        When signing to your account, the default project will be the one that
        is shown. You can see the project name on the top left corner.
      </P>

      <H4>Add a New Project</H4>
      <P>
        All projects are independent, as well as their new balances, widget key,
        and API keys.
      </P>
      <P>You can append existent/new agents to any project.</P>
      <P>
        You can add as many projects as you need, managing them through the
        "Settings" and "Project" Tab.{' '}
      </P>
      <P>To add a new project, click on "Add Project" on the top left menu.</P>
      <P>
        You can also replace current projects. To do this, just select "Replace
        Project".{' '}
      </P>
      <P>
        <ROUNDIMAGE>{addPorject}</ROUNDIMAGE>
      </P>

      <P>If you want to add a new project, you will see the following:</P>

      <SHARPIMAGE>{addnewProject}</SHARPIMAGE>
      <UL>
        <LI>
          Go to the Settings icon in the top right corner and select the{' '}
          <STRONG>"Project"</STRONG> tab.
        </LI>
        <LI>
          Upload the project logo (it will be displayed on the payment page).
        </LI>
        <LI>Enter your project name and specify your website address.</LI>
        <LI>
          Set up your currency. It will be used to display all the actions
          related to the current project.
        </LI>
        <LI>Add project description and select project color.</LI>
        <LI>
        Once all the required fields are filled, click "SAVE".
        </LI>
      </UL>

      <H4>Edit Your Project</H4>

      <P>
        To edit your project, go to the <STRONG>Settings</STRONG> and select the{' '}
        <STRONG>"Project"</STRONG> tab. You can change your project name,
        website, display currency, project description, and project color.{' '}
      </P>

    </PAGE>
  );
};

export default Index;
