import { useState, useEffect, useCallback, useRef } from 'react';
import { Time } from './utils';

export default function useTimer({ expiredAt, onExpire, immediate = false }) {
  const [seconds, setSeconds] = useState(null);
  const intervalRef = useRef();
  useEffect(() => {
    if (seconds) {
      intervalRef.current = setInterval(() => {
        setSeconds(seconds - 1);
      }, 950);
      return () => clearInterval(intervalRef.current);
    }
  }, [seconds, intervalRef]);

  useEffect(() => {
    if (expiredAt && immediate)
      setSeconds(Time.getSecondsFromExpiry(expiredAt));
  }, [expiredAt, immediate]);

  const start = useCallback(time => {
    if (time) setSeconds(Time.getSecondsFromExpiry(time));
  }, []);

  const stop = useCallback(() => {
    clearInterval(intervalRef.current);
  }, []);

  const onExpireExecute = useCallback(() => onExpire(), [onExpire]);

  useEffect(() => {
    if (seconds !== null && seconds <= 0) {
      clearInterval(intervalRef.current);
      setSeconds(null);
      onExpireExecute && onExpireExecute();
    }
  }, [seconds, onExpireExecute]);

  return {
    ...Time.getTimeFromSeconds(seconds),
    start,
    stop
  };
}
