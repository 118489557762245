import api from 'api';

function getCookie(name) {
  // Split cookie string and get all individual name=value pairs in an array
  var cookieArr = document.cookie.split(';');

  // Loop through the array elements
  for (var i = 0; i < cookieArr.length; i++) {
    var cookiePair = cookieArr[i].split('=');

    /* Removing whitespace at the beginning of the cookie name
        and compare it with the given string */
    if (name === cookiePair[0].trim()) {
      // Decode the cookie value and return
      return decodeURIComponent(cookiePair[1]);
    }
  }

  // Return null if not found
  return null;
}

export function submitHBSPTForm(formId, data) {
  const fields = Object.entries(data).map(([name, value]) => ({
    objectTypeId: '0-1',
    name,
    value
  }));

  fetch(
    `https://api.hsforms.com/submissions/v3/integration/submit/25662341/${formId}`,
    {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        fields,
        context: {
          hutk: getCookie('hubspotutk')
        }
      })
    }
  )
    .then(res => {
      if (res.status !== 200) {
        res.json().then(error => {
          api('/logs/unknownError', {
            method: 'POST',
            body: { ...error, type: 'hubSpot' }
          });
        });
      }
    })
    .catch(error => {
      api('/logs/unknownError', {
        method: 'POST',
        body: { ...error, type: 'hubSpot' }
      });
    });
}
