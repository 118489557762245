import React from 'react';
import { H1, P } from '../../../components';
import PAGE from '../../../components/page';
import { SHARPIMAGE } from '../../../components/images';
import addWallet from './addWallet.png';

const Index = () => {
  return (
    <PAGE>
      <H1>Wallet Setup</H1>
      <P>
        MilaCoins allows you to accept, store, send, and exchange funds in a
        properly secured cryptocurrency wallet system. Once you have registered
        your account, you can set up your wallets. When you create a new account
        with MilaCoins, four wallets will be automatically added by default:
        BTC, BCH, ETH, USDT USDC and USDP. You can start sending, receiving, and
        exchanging money from any of these wallets. If you want to create a
        wallet for some other currency, click the “ADD WALLET” button.
      </P>
      <SHARPIMAGE>{addWallet}</SHARPIMAGE>
    </PAGE>
  );
};

export default Index;
