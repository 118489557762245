class Position {
  constructor({
    _id,
    category,
    location,
    title,
    jobDescription,
    responsibilities,
    requirements,
    status,
    jobType
  }) {
    this.jobID = _id;
    this.category = category;
    this.location = location;
    this.status = status;
    this.title = title;
    this.jobDescription = jobDescription;
    this.responsibilities = responsibilities;
    this.requirements = requirements;
    this.jobType = jobType;
  }

  createResToForm() {
    return this.responsibilities.map((res, key) =>
      createObj(`Responsibility${key + 1}`, res)
    );
  }
  requirements;

  createReqToForm() {
    return this.requirements.map((req, key) =>
      createObj(`Requirement${key + 1}`, req)
    );
  }

  getCategory = () => {
    return this.category;
  };

  getTitle = () => {
    return this.title;
  };

  getLocation = () => {
    return this.location;
  };
}

const createObj = (key, value) => {
  return { [key]: value };
};

export default Position;

/*
  const createObj = (key, value)=>{
      return {[key]: value}
  }

        this.responsibilities = responsibilities.map((res,key)=>createObj(`Responsibility${key+1}`,res));
      this.requirements = requirements.map((req,key)=>createObj(`Requirement${key+1}`,req));
  */
