import React from 'react';
import PAGE from '../../../components/page';
import { STRONG, H1 } from '../../../components';
import { TABLE, THEAD, TH, TBODY, TD, TR } from '../../../components/table';
import useSupportedCurrencies from 'myHooks/useSupportedCurrencies';
import { Symbols as currenciesInfo } from 'constants/currencySymbol';

const Index = () => {
  const supportedCurrencies = useSupportedCurrencies();
  return (
    <PAGE>
      <H1>Supported Currencies</H1>
      <TABLE>
        <THEAD>
          <TH size="1/2">
            <STRONG>ISO 4217</STRONG>
          </TH>
          <TH size="1/2">
            <STRONG>Currency</STRONG>
          </TH>
        </THEAD>
        <TBODY>
          {supportedCurrencies.map(c => (
            <TR key={`supportedCurrencies-${c}`}>
              <TD size="1/2">
                <STRONG>{c}</STRONG>
              </TD>
              <TD size="1/2">{currenciesInfo[c]?.name || c}</TD>
            </TR>
          ))}
        </TBODY>
      </TABLE>
    </PAGE>
  );
};

export default Index;
