import { useState, useCallback } from 'react';

const useCountUp = ({ start = 0, end = 100, delay = 1, duration = 2 }) => {
  const [countUp, setCountUp] = useState(start);

  const run = useCallback(() => {
    if (end < start) {
      setCountUp(end);
      return;
    }
    setCountUp(start);
    setTimeout(() => {
      let interval = 5,
        step = (interval / (duration * 1000)) * (end - start),
        counter = start;

      let countUpInterval = setInterval(function() {
        counter = counter >= end ? end : counter + step;
        setCountUp(Number.parseInt(counter));
        if (counter >= end) {
          clearInterval(countUpInterval);
        }
      }, interval);
    }, delay * 1000);
  }, [start, end, delay, duration]);

  return { countUp, start: run };
};

export default useCountUp;
