import React from 'react';
import { Link } from 'react-router-dom';
import { useRouter } from 'myHooks';
import {
  FaLinkedin,
  FaFacebookSquare,
  FaTwitterSquare,
  FaInstagram
} from 'react-icons/fa';
import { IoLogoBitbucket } from 'react-icons/io';

const Index = ({ scrollIntoWhyMila }) => {
  const { pathname } = useRouter();
  const isHomePage = pathname === '/';
  return (
    <div className="flex flex-col items-center bgFooter p-10 text-white w-full">
      <div className="w-full px-3 md:px-0 md:w-11/12 xl:w-3/4 hpRegFont">
        <div className="flex flex-col space-y-5 lg:space-y-0 lg:flex-row">
          <div className="w-3/4">
            <div className="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-3">
              <div className="w-full pt-5 lg:pt-0 lg:flex flex-col">
                <ul className="space-y-2 flex flex-col">
                  <li className="text-xl font-bold">Company</li>
                  <Link to="/contact-us">
                    <li>Contact</li>
                  </Link>
                  {isHomePage ? (
                    <span
                      className="cursor-pointer"
                      onClick={() => scrollIntoWhyMila()}
                    >
                      {' '}
                      <li>Why MilaCoins</li>
                    </span>
                  ) : (
                    <Link to="/why-us">
                      <li>Why MilaCoins</li>
                    </Link>
                  )}
                  <Link to="/siteMap">
                    <li>Site Map</li>
                  </Link>
                </ul>
              </div>
              <div className="w-full pt-5 lg:pt-0 lg:flex flex-col">
                <ul className="space-y-2 flex flex-col">
                  <li className="text-xl font-bold">Work With Us</li>
                  <Link to="/create-account">
                    <li>Sign up</li>
                  </Link>
                  <Link to="/careers">
                    <li>Careers</li>
                  </Link>
                  <Link to="/pricing">
                    <li>Pricing</li>
                  </Link>
                  <Link to="/documentation">
                    <li>Docs Homepage</li>
                  </Link>
                  <a href="http://api.milacoins.com">
                    <li>API Documents</li>
                  </a>
                </ul>
              </div>
              <div className="w-full pt-5 lg:pt-0 lg:flex flex-col">
                <ul className="space-y-2 flex flex-col">
                  <li className="text-xl font-bold">Solutions</li>
                  <Link to="/eCommerce">
                    <li>eCommerce</li>
                  </Link>
                  <Link to="/professionalsServices">
                    <li>Professional Services</li>
                  </Link>
                  <Link to="/ITDevelopers">
                    <li>IT Developers</li>
                  </Link>
                  <Link to="/charities">
                    <li>Charities</li>
                  </Link>
                  <Link to="/merchants">
                    <li>Merchants</li>
                  </Link>
                </ul>
              </div>
              <div className="w-full pt-5 lg:pt-0 lg:flex flex-col">
                <ul className="space-y-2 flex flex-col">
                  <li className="text-xl font-bold">Legal</li>
                  <Link to="/privacy-policy">
                    <li>Privacy Policy</li>
                  </Link>
                  <Link to="/aml-policy">
                    <li>AML Policy</li>
                  </Link>
                  <Link to="/cookies-policy">
                    <li>Cookies Policy</li>
                  </Link>
                  <Link to="/terms-of-service">
                    <li>Terms of Service</li>
                  </Link>
                  <Link to="/not-allowed-countries">
                    <li>Countries not allowed</li>
                  </Link>
                </ul>
              </div>
            </div>
          </div>
          <div className="w-full flex flex-col lg:w-1/4">
            <p className="font-bold pb-4 text-base">
              MilaCoins is a robust and easy-to-use set of payment solutions
              under one roof that enable businesses of different sizes to move
              money more easily leveraging the power of blockchain.
            </p>
            <p className="text-left pt-1">
              ©2023 MilaCoins All rights reserved by MILATEAM LIMITED Company
              Number 516448016
            </p>
            <p>Email: support@milacoins.com</p>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center mt-8">
        <h5 className="font-semibold">Join Our Community</h5>
        <div className="flex mt-4 space-x-4">
          <a href="https://www.linkedin.com/company/milacoins-crypto-payment-gateway">
            <span className="flex flex-col cursor-pointer items-center">
              <FaLinkedin size={30} />
              <p>LinkedIn</p>
            </span>
          </a>
          <a href="https://www.facebook.com/MilaCoins-105858888560241">
            <span className="flex flex-col items-center">
              <FaFacebookSquare size={30} />
              <p>Facebook</p>
            </span>
          </a>
          <a href="https://www.instagram.com/milacoinscrypto/?utm_medium=copy_link">
            <span className="flex flex-col items-center">
              <FaInstagram size={30} />
              <p>Instagram</p>
            </span>
          </a>
          <a href="https://twitter.com/MilaCoinscom">
            <span className="flex flex-col items-center">
              <FaTwitterSquare size={30} />
              <p>Twitter</p>
            </span>
          </a>
          <a href="https://haim-dev@bitbucket.org/haim-dev/milauth.git">
            <span className="flex flex-col items-center">
              <IoLogoBitbucket size={30} />
              <p>BitBucket</p>
            </span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Index;
