import { combineReducers } from "redux";
import allColors from "./config/all-colors";
import colors from "./config/colors";
import config from "./config/config";
import leftSidebar from "./config/left-sidebar";
import palettes from "./config/palettes";
import routes from "./config/routes";
import auth from "./config/auth";

const rootReducer = combineReducers({
  allColors,
  colors,
  config,
  leftSidebar,
  palettes,
  routes,
  auth,
});

export default rootReducer;
