import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AppShell from "./appShell";
import Routes from "./Routes";
import Loader from "./components/loader";
import ErrorBoundary from "error-handler";
import "./css/tailwind.css";
import "./css/main.css";
import "./css/_components.css";
import "./css/_palettes.css";

const Wrapper = ({ children }) => {
  return <AppShell>{children}</AppShell>;
};

const App = () => {
  return (
    <Router>
      <ErrorBoundary>
        <Wrapper>
          <Loader />
          <Routes />
        </Wrapper>
      </ErrorBoundary>
    </Router>
  );
};
export default App;
