import { useAsyncAction } from 'myHooks';
import { publicActions } from 'actions';

function useSupportedCurrencies() {
  const { value, error } = useAsyncAction(
    publicActions.getSupportedCurrencies,
    true
  );

  if (error) console.log(error);

  return value || [];
}

export default useSupportedCurrencies;
