import { authActions } from 'actions';

export default function auth(
  state = {
    expiresAt: 0,
    expiresIn: 1000 * 60 * 60
  },
  action
) {
  switch (action.type) {
    case authActions.SET_EXPIRE_TIME:
      return {
        expiresAt: action.expiresAt,
        expiresIn: action.expiresIn
      };
    case authActions.LOGOUT:
      return {
        expiresAt: 0
      };
    default:
      return state;
  }
}
