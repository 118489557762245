//Import Routes
import PreLander from './pages/preLander';
import Introduction from './pages/basic/Introduction/index';
import GettingStarted from './pages/basic/GettingStarted/index';
import OnlinePayments from './pages/basic/OnlinePayments/index';
import DonatePayments from './pages/basic/DonatePayments/index';
import Environments from './pages/basic/Environments/index';
import Plugins from './pages/basic/Plugins';
import WooCommerce from './pages/basic/Plugins/WooCommerce';
import JumpSeller from './pages/basic/Plugins/Jumpseller';
import Wallet from './pages/basic/WalletSetup/index';
import WalletSetup from './pages/basic/SettingUpWallets/index';
import WalletManagement from './pages/basic/walletManegment/index';
import WalletTypes from './pages/basic/walletTypes/index';
// import WalletSetup from './pages/basic/WalletSetup/index';
// import WalletSetup from './pages/basic/WalletSetup/index';
import Invoicing from './pages/basic/Invoicing/index';
import ProjectSetup from './pages/basic/ProjectSetup/index';
import APISetup from './pages/basic/APISetup/index';
import ProfileSetup from './pages/basic/ProfileSetup/index';
import PaymentButton from './pages/basic/PaymentButton/index';
import CompanySetup from './pages/basic/CompanySetup/index';
import SecuritySetup from './pages/basic/SecuritySetup/index';
import WidgetPayment from './pages/basic/WidgetPayment/index';
import URLPayment from './pages/basic/URLPayment/index';
import Setup from './pages/basic/Setup/index';
import CreatingAccount from './pages/basic/CreatingAccount/index';
import Testing from './pages/basic/Testing/index';
import SupportedCurrencies from './pages/basic/SupportedCurrencies/index';
import SupportedWallets from './pages/basic/SupportedWallets';
import Receive from './pages/basic/walletTypes/receive/index';
import Send from './pages/basic/walletTypes/send/index';
import Exchange from './pages/basic/walletTypes/exchange/index';
import Withdraw from './pages/basic/walletTypes/withdraw/index';
import ButtonWizard from './pages/basic/ButtonWizard';
import Webhooks from './pages/basic/Webhook';
//Routes
export const RoutesOfDocs = [
  //always children first
  {
    path: '/documentation/Wallet/SettingUpWallets',
    Component: WalletSetup
  },
  {
    path: '/documentation/Wallet/WalletManagement',
    Component: WalletManagement
  },
  {
    path: '/documentation/Wallet/WalletTypes/receive',
    Component: Receive
  },
  {
    path: '/documentation/Wallet/WalletTypes/send',
    Component: Send
  },
  {
    path: '/documentation/Wallet/WalletTypes/exchange',
    Component: Exchange
  },
  {
    path: '/documentation/Wallet/WalletTypes/withdraw',
    Component: Withdraw
  },
  {
    path: '/documentation/Wallet/WalletTypes',
    Component: WalletTypes
  },
  {
    path: '/documentation/Wallet/WalletFunctionality',
    Component: WalletSetup
  },
  {
    path: '/documentation/Wallet/SupportedWallets',
    Component: SupportedWallets
  },
  {
    path: '/documentation/Wallet',
    Component: Wallet
  },
  {
    path: '/documentation/APISetup',
    Component: APISetup
  },
  {
    path: '/documentation/Webhooks',
    Component: Webhooks
  },
  {
    path: '/documentation/GettingStarted/CompanySetup',
    Component: CompanySetup
  },

  {
    path: '/documentation/GettingStarted/ProfileSetup',
    Component: ProfileSetup
  },
  {
    path: '/documentation/GettingStarted/ProjectSetup',
    Component: ProjectSetup
  },
  {
    path: '/documentation/GettingStarted/SecuritySetup',
    Component: SecuritySetup
  },
  {
    path: '/documentation/Setup',
    Component: Setup
  },
  {
    path: '/documentation/Payments/WidgetPayment',
    Component: WidgetPayment
  },
  {
    path: '/documentation/Payments/ButtonWizard',
    Component: ButtonWizard
  },
  {
    path: '/documentation/Payments/PaymentButtonStyle',
    Component: PaymentButton
  },
  {
    path: '/documentation/Payments/URLPayment',
    Component: URLPayment
  },
  {
    path: '/documentation/Payments/Donate',
    Component: DonatePayments
  },
  {
    path: '/documentation/Payments',
    Component: OnlinePayments
  },
  {
    path: '/documentation/Invoicing',
    Component: Invoicing
  },
  {
    path: '/documentation/Testing',
    Component: Testing
  },
  {
    path: '/documentation/Plugins/WooCommerce',
    Component: WooCommerce
  },
  {
    path: '/documentation/Plugins/JumpSeller',
    Component: JumpSeller
  },
  {
    path: '/documentation/Plugins',
    Component: Plugins
  },
  {
    path: '/documentation/GettingStarted/CreatingAccount',
    Component: CreatingAccount
  },
  {
    path: '/documentation/GettingStarted/Environments',
    Component: Environments
  },
  {
    path: '/documentation/Introduction',
    Component: Introduction
  },
  {
    path: '/documentation/SupportedCurrencies',
    Component: SupportedCurrencies
  },
  {
    path: '/documentation/GettingStarted',
    Component: GettingStarted
  },
  {
    path: '/documentation',
    Component: PreLander
  }
];

//Left Sidebar Categories
export const leftSideNavigation = [
  {
    //category headLine
    title: 'Documentation',
    items: [
      {
        title: 'Introduction',
        url: '/documentation/Introduction',
        items: []
      },
      {
        title: 'Getting Started',
        url: '/documentation/GettingStarted',
        items: [
          {
            title: 'Environments',
            url: '/documentation/GettingStarted/Environments',
            items: []
          },
          {
            title: 'Create Account',
            url: '/documentation/GettingStarted/CreatingAccount',
            items: []
          },
          {
            title: 'Company Setup',
            url: '/documentation/GettingStarted/CompanySetup',
            items: []
          },
          {
            title: 'Project Setup',
            url: '/documentation/GettingStarted/ProjectSetup',
            items: []
          },
          {
            title: 'Security Setup',
            url: '/documentation/GettingStarted/SecuritySetup',
            items: []
          },
          {
            title: 'Profile Setup',
            url: '/documentation/GettingStarted/ProfileSetup',
            items: []
          }
        ]
      },
      {
        title: 'Wallet',
        url: '/documentation/Wallet',
        items: [
          {
            title: 'Wallet Setup',
            url: '/documentation/Wallet/SettingUpWallets',
            items: []
          },
          {
            title: 'Wallet Management',
            url: '/documentation/Wallet/WalletManagement',
            items: []
          },
          {
            title: 'Supported Wallets',
            url: '/documentation/Wallet/SupportedWallets',
            items: []
          },
          {
            title: 'Wallet Types',
            url: '/documentation/Wallet/WalletTypes',
            items: [
              {
                title: 'Receive',
                url: '/documentation/Wallet/WalletTypes/receive',
                items: []
              },
              {
                title: 'Send',
                url: '/documentation/Wallet/WalletTypes/send',
                items: []
              },
              {
                title: 'Exchange',
                url: '/documentation/Wallet/WalletTypes/exchange',
                items: []
              },
              {
                title: 'Withdraw',
                url: '/documentation/Wallet/WalletTypes/withdraw',
                items: []
              }
            ]
          }
        ]
      },
      {
        title: 'Invoicing',
        url: '/documentation/Invoicing',
        items: []
      },
      {
        title: 'Payment Tools',
        url: '/documentation/Payments',
        items: [
          {
            title: 'Payment Widget',
            url: '/documentation/Payments/WidgetPayment',
            items: []
          },
          {
            title: 'Button Style',
            url: '/documentation/Payments/PaymentButtonStyle',
            items: []
          },
          {
            title: 'Button Wizard',
            url: '/documentation/Payments/ButtonWizard',
            items: []
          },
          {
            title: 'Payment Request',
            url: '/documentation/Payments/URLPayment',
            items: []
          },
          {
            title: 'Crypto Donations',
            url: '/documentation/Payments/Donate',
            items: []
          }
        ]
      },
      {
        title: 'Plugins',
        url: '/documentation/Plugins',
        items: [
          {
            title: 'WooCommerce',
            url: '/documentation/Plugins/WooCommerce',
            items: []
          },
          {
            title: 'JumpSeller',
            url: '/documentation/Plugins/JumpSeller',
            items: []
          }
        ]
      },
      {
        title: 'Testing',
        url: '/documentation/Testing',
        items: []
      },
      {
        title: 'Supported Currencies',
        url: '/documentation/SupportedCurrencies',
        items: []
      },
      {
        title: 'API Setup',
        url: '/documentation/APISetup',
        items: []
      },
      {
        title: 'Webhooks',
        url: '/documentation/Webhooks',
        items: []
      }
      //   {
      //     title: 'Plugins',
      //     url: '/documentation/Plugins',
      //     items: []
      //   }
    ]
  }
];

const getNavigatorArr = (item = {}) => {
  let tempArr = [];
  tempArr.push(item);
  if (Array.isArray(item?.items))
    tempArr = tempArr.concat(
      item?.items.reduce((accumulator, currentValue) => {
        return accumulator.concat(getNavigatorArr(currentValue));
      }, [])
    );
  return tempArr;
};
export const navigatorArr = getNavigatorArr(leftSideNavigation[0]);

//Navbar Links
export const navBar = [
  {
    title: 'Useful Links',
    items: [
      {
        title: 'Documentation',
        url: '/documentation',
        items: []
      },
      {
        title: 'API Reference',
        url: '/documentation/APISetup',
        items: []
      },
      {
        title: 'SignUp',
        url: '/create-account',
        items: []
      },
      {
        title: 'Log In',
        url: '/login',
        items: []
      }
    ]
  }
];
