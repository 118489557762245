import api from 'api';
import { submitHBSPTForm } from 'functions/hubspot';

export const sendContact = data => {
  return async () => {
    const body = {
      phone: `+${data.phoneCode?.value || ''}${data.phoneNumber || '00000000'}`
    };
    Object.keys(data).forEach(el => {
      if (
        data[el] &&
        ['name', 'email', 'country', 'message', 'phone'].includes(el)
      )
        if (el === 'country') body[el] = data[el].value;
        else body[el] = data[el];
    });
    submitHBSPTForm('5a8f96f3-6bf4-49aa-9355-6441f1642d3c', {
      email: body.email,
      firstName: body.name,
      phone: body.phone,
      country: body.country,
      message: body.message
    });

    return await api(`/public/contact`, { method: 'POST', body });
  };
};

export const getFees = () => {
  return async () => {
    return await api(`/public/fees`);
  };
};

export const getSupportedCurrencies = () => {
  return async () => {
    return await api(`/public/supportedCurrencies`);
  };
};

export const getSupportedWallets = () => {
  return async () => {
    return await api(`/public/supportedWallets`);
  };
};
