import React from 'react';

export default ({ className = '', link }) => {
  return (
    <div className={`p-1 w-full ${className}`}>
      <div
        className={`border items-center flex flex-row space-x-4 md:px-5 md:py-5 px-2 py-2 rounded relative 'border-red-600 bg-red-100 text-red-600`}
      >
        <strong className="font-bold md:text-sm text-3xs">
          <p className="break-normal">
            It is a Sandbox environment!{' '}
            <a
              href="https://milacoins.com/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <span className="underline">
                Click here for real environment.
              </span>
            </a>
            <br /> Make the payment on the testnet (
            <a
              href="https://coinfaucet.milacoins.com/ethereum.html"
              rel="noopener noreferrer"
              target="_blank"
            >
              <span className="underline">Ethereum: goerli</span>
            </a>
            ).{' '}
            <a
              href="https://coinfaucet.milacoins.com/bitcoin.html"
              rel="noopener noreferrer"
              target="_blank"
            >
              <span className="underline">Bitcoin testnet wallet</span>
            </a>
          </p>
        </strong>
      </div>
    </div>
  );
};
