import React from "react";
import PAGE from "../../../components/page";
import { P, H1, UL, LI, STRONG, BR, TAB } from "../../../components";
import { SHARPIMAGE } from "../../../components/images";
import RequestPayment from "./RequestPayment.png";
import RequestPayment2 from "./RequestPayment2.png";
import laststep from "./laststep.png";

const Index = () => {
  return (
    <PAGE>
      <H1>Request Payment</H1>
      <BR />
      <P>
        <STRONG>To generate a payment link, do the following:</STRONG>
      </P>
      <TAB>
        <UL>
          <LI>
            Go to the{" "}
            <a href="/backoffice/payments-tools/payment-request">
              Request Payment
            </a>{" "}
            Page.
          </LI>
          <LI>Enter the amount to be paid in your preferred currency.</LI>
          <LI>Specify the recipient’s wallet currency.</LI>
          <LI>Set the link expiration date.</LI>
          <LI>Press the “GENERATE LINK” button.</LI>
        </UL>
      </TAB>
      <BR />
      <P>
      It is possible to send payment requests by email, SMS, WhatsApp, etc. Sending online payment links to customers is an ideal solution for companies that don’t have an ecommerce store, but still want to accept payments in cryptocurrencies.
      </P>
      <BR />
      <SHARPIMAGE>{RequestPayment}</SHARPIMAGE>
      <UL>
        <LI>
          <STRONG>Customer</STRONG>(optional) - name of the customer.
        </LI>
        <LI>
          <STRONG>Recipient Wallet</STRONG> - what wallet payment goes to (the
          seller’s wallet).
        </LI>
        <LI>
          <STRONG>Currency and amount</STRONG> - what currency the customer will
          pay with.{" "}
        </LI>

        <LI>
          <STRONG>Expires At</STRONG> - when the checkout URL expires.{" "}
        </LI>
      </UL>
      <P> Click "GENERATE LINK" to get the Checkout URL.</P>
      <SHARPIMAGE>{RequestPayment2}</SHARPIMAGE>
      <UL>
        <LI>
          The recipient of this payment link will need to fill out First Name
          (optional), Last Name (optional) and Email (Required) in the
          corresponding fields.
        </LI>
      </UL>
      <SHARPIMAGE>{laststep}</SHARPIMAGE>
      <UL>
        <LI>
        The customer can copy the cryptocurrency address for sending the funds or scan the QR code instead.{" "}
        </LI>

        <LI>The customer will receive an email with the invoice.</LI>
      </UL>
    </PAGE>
  );
};

export default Index;
