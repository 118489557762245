import React from 'react';
import { H1, P } from '../../../components';
import PAGE from '../../../components/page';

const Index = () => {
  return (
    <PAGE>
      <H1>Wallet Management</H1>
      <P>
        MilaCoins Wallet allows you to manage your crypto assets directly on the
        platform. MilaCoins allows you to open a separate wallet for each
        supported currency. You can get settled directly to your bank account.
      </P>
    </PAGE>
  );
};

export default Index;
