import { useRef, useCallback } from 'react';

function useScrollIntoView({
  behavior = 'smooth',
  block = 'start',
  inline = 'start'
} = {}) {
  const ref = useRef(null);
  const scrollIntoView = useCallback(() => {
    if (ref.current)
      ref.current.scrollIntoView({
        behavior,
        block,
        inline
      });
  }, [behavior, block, inline]);

  return [ref, scrollIntoView];
}

export default useScrollIntoView;
