import React from 'react';
import PAGE from '../../../components/page';
import { H1, P, H4, STRONG, LINK, BR, UL, LI, TAB } from '../../../components';
import { SHARPIMAGE } from '../../../components/images';
import img1 from './button-wizard-1.png';
import img2 from './button-wizard-2.png';
import img3 from './button-wizard-3.png';
import img4 from './button-wizard-4.png';
import img5 from './button-wizard-5.png';
import img6 from './button-wizard-6.png';

const Index = () => {
  return (
    <PAGE>
      <H1>Button Wizard</H1>
      <P>
        By using the button wizard tool, you can generate and customize a
        payment button for your website.
        <br />
        The wizard generates a client-side code to implement the{' '}
        <LINK to="/documentation/Payments/WidgetPayment" target="_blank">
          MilaCoins widget
        </LINK>
        .
        <br />
        With this tool you can create unlimited buttons without any coding
        skills. All you need to do is to access the checkout page and to paste
        the code.
      </P>
      <P>
        <STRONG>
          Follow the steps below to add the MilaCoins payment button to your
          website.
        </STRONG>
      </P>
      <UL>
        <LI>Download the plugin from your MilaCoins account.</LI>
        <LI>
          Configure and customize the button with the{' '}
          <STRONG>Button Wizard</STRONG> tool.
        </LI>
        <LI>Copy the code to your checkout page.</LI>
      </UL>

      <BR />
      <BR />

      <H4>Configure and customize the button</H4>
      <UL>
        <LI>
          <LINK to="https://milacoins.com/login" target={'_blank'}>
            Log in
          </LINK>{' '}
          to your MilaCoins account -{' '}
          <LINK to="https://milacoins.com/create-account">click here </LINK> if
          you do not have one.
        </LI>
        <LI>
          Navigate To{' '}
          <LINK
            to="https://milacoins.com/backoffice/payments-tools/buttonWizard"
            target={'_blank'}
          >
            Button Wizard
          </LINK>
          : <STRONG>Payment Tools</STRONG> {'>'}{' '}
          <STRONG>Setup Payment Button</STRONG> {'>'}{' '}
          <STRONG>Button Wizard.</STRONG>
        </LI>
        <LI>
          Follow the button wizard steps:
          <TAB>
            <STRONG>1. Set item id:</STRONG> A unique record identifier
            representing a single content item from your system that will be
            saved as “External ID” on MilaCoins system. (optional step)
            <SHARPIMAGE>{img1}</SHARPIMAGE>
            <STRONG>2. Item price:</STRONG> Set the price and the currency of
            your item.
            <SHARPIMAGE>{img2}</SHARPIMAGE>
            <STRONG>3. Set wallet target:</STRONG> Choose the wallet (currency)
            for receiving all your automatically exchanged crypto payments.
            <SHARPIMAGE>{img3}</SHARPIMAGE>
            <STRONG>4. Set complete page:</STRONG> page URL that MilaCoins will
            redirect to your customer after successful payment.
            <br />
            This URL needs to be public.
            <br />
            MilaCoins will connect the payment details to the url with the
            following parameters: id, amount, currency, itemID.
            <br />
            You can skip this step if you wish; MilaCoins will display a success
            message with the payment reference.
            <SHARPIMAGE>{img4}</SHARPIMAGE>
            <STRONG>5. Set Customize payment button:</STRONG> Select the style
            of your payment buttons to match your website design.
            <SHARPIMAGE>{img5}</SHARPIMAGE>
          </TAB>
        </LI>
      </UL>

      <BR />
      <BR />
      <BR />
      <BR />

      <H4>Copy the code to your checkout page</H4>
      <UL>
        <LI>
          Press on "Copy Code".
          <SHARPIMAGE>{img6}</SHARPIMAGE>
        </LI>
        <LI>
          Paste code on your checkout page. <br />
          <STRONG>Note: </STRONG>The code will work with any app (website) that
          supports HTML and JavaScript.
        </LI>
      </UL>
      <BR />
      <BR />
      <P>
        <STRONG>Note: </STRONG>To begin with, it is highly recommended that you
        try the Button Wizard in a Sandbox environment first.
        <br />
        You can use our Bitcoin testnet wallet to complete payments in MilaCoins
        Sandbox environment. Learn more about{' '}
        <LINK to="/documentation/Testing">testing</LINK>.
      </P>
    </PAGE>
  );
};

export default Index;
