import React from 'react';
import PAGE from '../../../components/page';
import { H1, P, UL, LI, H4, LINK } from '../../../components';
import { SHARPIMAGE } from '../../../components/images';
import recive from './recive.png';
import sendBitcoin from './sendBitcoin.png';

const Index = () => {
  return (
    <PAGE>
      <H1>Testing</H1>

      <P>
        For development and testing, MilaCoins users can access{' '}
        <LINK to=" https://sandbox.milacoins.com/">
          https://sandbox.milacoins.com/
        </LINK>
        , a clone of our production site running on testnet.
      </P>
      <P>
        You can open your Sandbox account and test how the system works. If you
        want to get the system tested by your developers, they will need to go
        through a standard sign-up process. Below are some helpful tips for
        getting started with MilaCoins SandBox.
      </P>

      <H4>Getting A BTC Testnet</H4>

      <UL>
        <LI>
          Visit{' '}
          <LINK to="https://sandbox.milacoins.com/backoffice/wallet/BTC">
            https://sandbox.milacoins.com/backoffice/wallet/BTC
          </LINK>{' '}
          (after opening a testnet account).{' '}
        </LI>
      </UL>

      <SHARPIMAGE>{recive}</SHARPIMAGE>
      <UL>
        <LI>Go to the "Receive" tab.</LI>
        <LI>Copy the address.</LI>
        <LI>
          Find a testnet faucet and paste your testnet wallet address into the
          provided request form. We recommend the following testnet faucets:
          <P>
            <LINK to="https://coinfaucet.milacoins.com/bitcoin.html">
              coinfaucet-bitcoin milacoins
            </LINK>
          </P>
          <P>
            <LINK to="testnet-faucet.mempool.co">
              testnet-faucet.mempool.co
            </LINK>
          </P>
          <P>
            <LINK to="https://testnet.coinfaucet.eu/">
              https://testnet.coinfaucet.eu/
            </LINK>{' '}
          </P>
        </LI>
        <LI>
          After receiving testnet Bitcoin, you can test the send and exchange
          methods.
        </LI>
      </UL>
      <SHARPIMAGE>{sendBitcoin}</SHARPIMAGE>

      <UL>
        <LI>
          Also, you can test online payments (the widget and/or the payment link
          generator) with testnet Bitcoins in the sandbox network{' '}
          <LINK to="/documentation/Payments">Online Payments</LINK>.{' '}
        </LI>
      </UL>

      <H4>Testing Considerations</H4>
      <P>
        Testing might involve not only the integration of the MilaCoins service
        and the payment of the Payment links. Consider testing the following
        transactions to understand how it works{' '}
      </P>
      <UL>
        <LI>fully paid invoices, paid on time.</LI>
        <LI>fully paid invoices, paid late.</LI>
        <LI>underpaid invoices.</LI>
        <LI>overpaid invoices.</LI>
      </UL>
    </PAGE>
  );
};

export default Index;
