import React from 'react';
import { H1, P, H4 } from '../../../components';
import PAGE from '../../../components/page';
import { SHARPIMAGE } from '../../../components/images';
import usersSetup from './usersSetup.png';
import CompanySetup from './CompanySetup.png';
import BankSetup from './BankSetup.png';

const Index = () => {
  return (
    <PAGE>
      <H1>Company Setup</H1>

      <P>
        The company tab will bring you to the setup of your bank account and
        agents of your company (Users).
      </P>

      <SHARPIMAGE>{CompanySetup}</SHARPIMAGE>

      <H4>Bank Setup</H4>

      <P>
        Enter your bank account details and get them verified by the MilaCoins
        customer support.
      </P>
      <P>
        Once your bank account is verified, you will be able to accept payments
        using the MilaCoins platform.
      </P>

      <SHARPIMAGE>{BankSetup}</SHARPIMAGE>

      <H4>Users</H4>

      <P>
        You can add users in the Company Tab of your account settings. Users can
        be assigned different roles such as "Admin", "Compliance", "IT" and
        "Support".
      </P>
      <P>
        When creating a new User, enter the name of the User, Email, Role and
        the name of Project the User is involved in. Once these details are
        submitted, ask the User to verify their email address.
      </P>
      <P>
        In the verification email, the User will receive a generated password.
        It’s highly recommended to change the password after the first use.
      </P>

      <SHARPIMAGE>{usersSetup}</SHARPIMAGE>
    </PAGE>
  );
};

export default Index;
