import React from 'react';
import { H1, H4, P, UL, LI, STRONG, BR, LINK } from '../../../components';
import PAGE from '../../../components/page';

const Index = () => {
  return (
    <PAGE>
      <H1>Getting Started</H1>
      <P>
        {' '}
        <STRONG>Accept crypto payments:</STRONG>
      </P>
      <UL>
        <LI>By sending payment links to your customers.</LI>
        <LI>By adding a payment widget into your website.</LI>
      </UL>
      <BR />
      <H4>To start accepting crypto payments, do the following:</H4>
      <UL>
        <LI> Open an account.</LI>
        <LI> Set up the Project logo and main currency.</LI>
        <LI> Set up Wallet(s) to accept payments.</LI>
        <LI>
          Add the MilaCoins payment widget to your website or use our payment
          link generator.
        </LI>
      </UL>
      <BR />
      <H4>Open an account</H4>
      <UL>
        <LI>
          Press <STRONG>“Sign Up”</STRONG> on the top right corner of the
          website or click <STRONG>“Create an account”</STRONG> below the login
          form.
        </LI>
        <LI>Fill in the required details (Company, Email, and Password).</LI>
        <LI>
          Read and Accept our{' '}
          <LINK to="/terms-of-service">“Terms of Service”</LINK> &{' '}
          <LINK to="/privacy-policy">“Privacy Policy”.</LINK>{' '}
        </LI>
        <LI>
          Go to your email inbox and follow the verification link to confirm
          your email.
        </LI>
      </UL>
      <P>
        Can't find an email with the verification link? Click the Resend Button.
        If you still don’t see it in your inbox, check your Spam folder, it
        might have ended up there.
      </P>
      <BR />
      <H4>Project Setup</H4>
      <P>
        Go to the Settings icon in the top right corner and select the{' '}
        <STRONG>"Project"</STRONG> tab.
      </P>
      <UL>
        <LI>
          Upload the project logo (it will be displayed on the payment page).
        </LI>
        <LI>Enter your project name and specify your website address.</LI>
        <LI>
          Set up your currency. It will be used to display all the actions
          related to the current project.
        </LI>
        <LI>Add project description and select project color.</LI>
        <LI>
          Once all the required fields are filled, click <STRONG>"SAVE”</STRONG>
          .
        </LI>
      </UL>

      <BR />
      <H4>Wallet Setup</H4>
      <P>
        Once you create an account with MilaCoins, 4 wallets will be added by
        default (BTC, BCH, EUR, and USD). Once the wallet has been added, you
        can start accepting payments into it.
      </P>
      <P>
        To set up a new wallet for some other currency, click the “Add WALLET”
        button (on top of the wallets) and find your preferred currency in the
        list.
      </P>
      <P>
        If the wallet appears in the list, you can begin accepting payments into
        it.
      </P>
      <BR />
      <H4>Start Accepting Payments</H4>
      <UL>
        <P>1) By requesting a payment through MilaCoins web interface:</P>
        <UL>
          <LI>
            Go to the payment link generator{' '}
            <a
              href="/backoffice/payments-tools/payment-request"
              className="link text-sm sm:text-lg break-words"
            >
              page.
            </a>
          </LI>
          <LI>
            Specify your customer’s name, your currency, and the exact amount
            you want to request.
          </LI>
          <LI>
            Choose the recipient’s wallet currency and expiration date of your
            payment link.
          </LI>
          <LI>
            Press the “Request Payment” button and get an payment link generated
            by the system in seconds.
          </LI>
        </UL>
        <P>
          2) By using MilaCoins Payment Widget App:
          <P>
            To integrate MilaCoins widget to your website, go to
            <LINK to="/documentation/Payments/WidgetPayment"> this page.</LINK>
          </P>
        </P>
      </UL>

      <BR />
    </PAGE>
  );
};

export default Index;
