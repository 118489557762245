import React from 'react';
import PAGE from '../../../components/page';
import { H1, P, H4, BR, UL, LI, STRONG } from '../../../components';

const Index = () => {
  return (
    <PAGE>
      <H1>Plugins</H1>
      <H4>MilaCoins Plugins for popular e-commerce sites</H4>
      <P>
        Plugins help to connect your online shopping platform that supports your
        e-commerce operations to the automated crypto payment processing
        capabilities of MilaCoins Payment Gateway.
        <br />
        Each MilaCoins Plugin comes with a clear guide with easy to follow
        instructions that will help you connect your website to our trusted
        crypto payment gateway.
        <br />
        With the help of MilaCoins plugins, it is simple to add reliable,
        frictionless payment processing to your online store or an ecommerce
        platform. Your customers seamlessly step from shopping on their store to
        checkout and payment, all contained inside MilaCoins crypto payment
        platform. With the help of MilaCoins plugins your customers get a fully
        integrated suite of payment products they can buy with crypto.
      </P>
      <BR />
      <H4>How to start</H4>
      <P>
        MilaCoins plugins enable you to begin accepting cryptocurrencies in just
        a few clicks.
      </P>
      <P>
        All you need to do is sign up for a MilaCoins account, go to the plugins
        area and select your option from the list of e-commerce plugins
        suggested by MilaCoins.
      </P>
      <P>
        <STRONG>Steps to take: </STRONG>
      </P>
      <UL>
        <LI>Choose your plugin option and click on it.</LI>
        <LI>
          Follow the steps to add your preferred MilaCoins plugin to your store.
        </LI>
        <LI>Start accepting crypto as a payment for your e-commerce items.</LI>
      </UL>
      <BR />
    </PAGE>
  );
};

export default Index;
