import React from 'react';

const PAGE = ({ children }) => {
  return (
    <div>
      <div className="">{children}</div>
    </div>
  );
};

export default PAGE;
