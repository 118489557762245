import React, { useState, useEffect } from 'react';
import logo from 'assets/logoPub.svg';
import { Link } from 'react-router-dom';

const Index = () => {
  const [scrollOnTop, setScrollOnTop] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 80) {
        setScrollOnTop(true);
      } else {
        setScrollOnTop(false);
      }
    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => window.removeEventListener('scroll', handleScroll);
  }, [scrollOnTop]);

  return (
    <>
      <Link to="/" className="p-0">
        <img
          className={`w-auto transition duration-1000 ease-in-out ${
            scrollOnTop ? 'transform scale-75 h-10 md:h-12' : 'h-12 md:h-16'
          }`}
          src={logo}
          alt="logoMila"
        ></img>
      </Link>
    </>
  );
};

export default Index;
