import React from 'react';

export const MILABTN = () => {
  return (
    <div className="w-full m-3 2xl:w-1/2 flex items-center justify-center md:space-x-2 py-1 rounded bg-mila-btnwidget">
      <h6 className="text-white text-sm sm:text-3xs md:text-sm hpRegFont">
        Pay Crypto With
      </h6>
      <img
        className="h-6 sm:h-4 md:h-8"
        src="https://sandbox.milacoins.com/static/assets/btnLogo.svg"
        alt=""
      />
    </div>
  );
};

export const SECONDARYBTN = () => {
  return (
    <div className="w-full 2xl:w-1/2 m-3 flex items-center justify-center md:space-x-2 py-1 rounded bg-mila-400">
      <h6 className="text-white text-sm sm:text-3xs md:text-sm md:text-sm hpRegFont">
        Pay Crypto With
      </h6>
      <img
        className="h-6 sm:h-4 md:h-8"
        src="https://sandbox.milacoins.com/static/assets/btnLogo.svg"
        alt=""
      />
    </div>
  );
};

export const BLACKBTN = () => {
  return (
    <div className="w-full 2xl:w-1/2 m-3 flex items-center justify-center md:space-x-2 py-1 rounded bg-black">
      <h6 className="text-white text-sm sm:text-3xs md:text-sm md:text-sm hpRegFont">
        Pay Crypto With
      </h6>
      <img
        className="h-6 sm:h-4 md:h-8"
        src="https://sandbox.milacoins.com/static/assets/btnLogo.svg"
        alt=""
      />
    </div>
  );
};

export const WHITEBTN = () => {
  return (
    <div className="w-full 2xl:w-1/2 m-3 flex items-center justify-center md:space-x-2 py-1 border border-black rounded bg-white">
      <h6 className="text-black text-sm sm:text-3xs md:text-sm md:text-sm hpRegFont">
        Pay Crypto With
      </h6>
      <img
        className="h-6 sm:h-4 md:h-8"
        src="https://sandbox.milacoins.com/static/assets/btnLogoWhite.svg"
        alt=""
      />
    </div>
  );
};

export const SHARPBTN = () => {
  return (
    <div className="w-full 2xl:w-1/2m-3 flex items-center justify-center md:space-x-2 py-1 bg-mila-btnwidget">
      <h6 className="text-white text-sm sm:text-3xs md:text-sm md:text-sm hpRegFont">
        Pay Crypto With
      </h6>
      <img
        className="h-6 sm:h-4 md:h-8"
        src="https://sandbox.milacoins.com/static/assets/btnLogo.svg"
        alt=""
      />
    </div>
  );
};

export const ROUNDEDFULLBTN = () => {
  return (
    <div className="w-full 2xl:w-1/2m-3 flex items-center justify-center md:space-x-2 py-1 rounded-full bg-mila-btnwidget">
      <h6 className="text-white text-sm sm:text-3xs md:text-sm md:text-sm hpRegFont">
        Pay Crypto With
      </h6>
      <img
        className="h-6 sm:h-4 md:h-8"
        src="https://sandbox.milacoins.com/static/assets/btnLogo.svg"
        alt=""
      />
    </div>
  );
};
