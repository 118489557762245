import React from 'react';
import { H1, P, H4, BR,UL,LI } from '../../../components';
import PAGE from '../../../components/page';

const Index = () => {
  return (
    <PAGE>
      <H1>Wallet Types</H1>
      <H4>
      There are two types of internal wallets on the MilaCoins platform:
      </H4>
      <UL>
        <LI>crypto wallets</LI>
        <LI>fiat wallets</LI>
      </UL>
      <BR />
      <H4>Crypto wallets</H4>
      <P>
      Crypto wallets let you accept, send, and exchange crypto. With MilaCoins crypto wallets, you can manage all your digital assets in a single, ultra secure place.
      </P>
      <BR />
      <H4>Fiat wallets</H4>
      <P>
      Fiat wallets
Fiat wallets are used to make withdrawals to your bank account. Also, they can be used to exchange funds within the MilaCoins platform.

      </P>
    </PAGE>
  );
};

export default Index;
