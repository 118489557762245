//Navbar Links
export const navBar = [
  {
    title: 'Get Started',
    link: '/get-started',
    classOfLink:
      'hpRegFont text-base px-5 transform transition duration-300 hover:scale-105 ease-in-ou hover:text-mila-300'
  },
  {
    title: 'Why MilaCoins',
    link: '/why-us',
    classOfLink:
      'hpRegFont text-base px-5 transform transition duration-300 hover:scale-105 ease-in-ou hover:text-mila-300'
  },
  {
    title: 'Documentation',
    link: '/documentation',
    classOfLink:
      'hpRegFont text-base px-5 transform transition duration-300 hover:scale-105 ease-in-ou hover:text-mila-300'
  },
  {
    title: 'FAQ',
    link: '/FAQ',
    classOfLink:
      'hpRegFont text-base px-5 transform transition duration-300 hover:scale-105 ease-in-ou hover:text-mila-300'
  },
  {
    title: 'Login',
    link: '/login',
    classOfLink:
      'hpRegFont text-base px-5 transform transition duration-300 hover:scale-105 ease-in-ou ml-16 hover:text-mila-300'
  },
  {
    title: 'Sign Up',
    link: '/create-account',
    classOfLink:
      'hpRegFont  text-base px-5 transform transition duration-300 hover:scale-105 ease-in-ou py-2 -mt-1 ml-4 btn-outlined bg-transparent text-mila-300 border rounded border-mila-300 hover:text-mila-300 hover:bg-grey-100'
  },
  {
    title: 'Contact',
    link: '/contact-us',
    classOfLink:
      'hpRegFont text-base px-5 transform transition duration-300 hover:scale-105 ease-in-ou hover:text-mila-300'
  }
];
