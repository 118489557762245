import React from 'react';
import { Link } from 'react-router-dom';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

const Index = ({ next, prev }) => {
  return (
    <div className="flex mt-5 px-2 sm:px-10 space-x-2">
      {prev === 'noBack' ? (
        <></>
      ) : (
        <div
          className={`${
            prev === 'noBack' ? 'w-1/2' : 'w-full md:w-7/12'
          } border border-grey-300 p-4 flex flex-col hover:text-mila-300 cursor-pointer`}
        >
          <Link to={prev.url}>
            <span className="flex">
              <span className="ml-1">
                <IoIosArrowBack size={30} />
              </span>
              <h6 className="font-semibold">Back</h6>
            </span>
            <h6 className="text-mila-300">{prev.title}</h6>
          </Link>
        </div>
      )}
      {next === 'noNext' ? (
        <></>
      ) : (
        <div
          className={`${
            next === 'noNext' ? 'w-1/2 items-end' : 'w-full md:w-8/12'
          } border border-grey-300 p-4 flex flex-col hover:text-mila-300 cursor-pointer`}
        >
          <Link to={next.url}>
            <span className="flex ">
              <h6 className="font-semibold">Next</h6>
              <span className="mr-1">
                <IoIosArrowForward size={30} />
              </span>
            </span>
            <h6 className="text-mila-300">{next.title}</h6>
          </Link>
        </div>
      )}
    </div>
  );
};

export default Index;
