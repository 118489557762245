import React from 'react';
import { H1, P, UL, LI, STRONG } from '../../../components';
import PAGE from '../../../components/page';
import { SHARPIMAGE } from '../../../components/images';
import ProfileSetup from './ProfileSetup.png';

const Index = () => {
  return (
    <PAGE>
      <H1>Profile Setup</H1>
      <P>
        Go to the <STRONG>"Profile"</STRONG> Tab of your account settings.
        Upload your <STRONG>project image</STRONG>, enter your{' '}
        <STRONG>name</STRONG>, specify the <STRONG>main Project</STRONG> and
        choose your preferred
        <STRONG>theme</STRONG>. Please note that you can change your profile
        settings any time.
      </P>

      <SHARPIMAGE>{ProfileSetup}</SHARPIMAGE>
      <UL>
        <LI>
          <STRONG>Name:</STRONG> the name of the agent.
        </LI>
        <LI>
          <STRONG>Main Project:</STRONG> what project will be open by default
          after logging in.
        </LI>
        <LI>
          <STRONG>Theme:</STRONG>light or dark theme
        </LI>
      </UL>
    </PAGE>
  );
};

export default Index;
