import { useState, useEffect } from 'react';

const useLocationByIP = () => {
  const [value, setValue] = useState(null);
  //   const [error, setError] = useState(null);
  //   const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      //   setIsLoading(true);
      try {
        const res = await fetch(
          'https://pro.ip-api.com/json?key=87XPF0Arz9iD05Z'
        );
        const json = await res.json();
        setValue(json);
        // setIsLoading(false);
      } catch (error) {
        setValue({ countryCode: 'IL' });
        // setError(error);
      }
    };
    fetchData();
  }, []);
  return value || {};
  //   return { value, error, isLoading };
};

export default useLocationByIP;
