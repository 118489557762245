import React, { useState, useEffect } from 'react';
import Lottie from 'react-lottie';
import { ImSpinner2 } from 'react-icons/im';
import * as animationData from './waitingAnimation/waiting.json';

const Waiting = ({ size, type = 'dots' }) => {
  const [width, setWidth] = useState(120);
  const [hight, setHight] = useState(60);

  useEffect(() => {
    switch (size) {
      case '2xl':
        setWidth(220);
        setHight(160);
        break;
      case 'xl':
        setWidth(190);
        setHight(130);
        break;
      case 'lg':
        setWidth(150);
        setHight(90);
        break;
      case 'md':
        setWidth(120);
        setHight(60);
        break;
      case 'sm':
        setWidth(90);
        setHight(30);
        break;
      default:
        setWidth(120);
        setHight(60);
        break;
    }
  }, [size]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  switch (type) {
    case 'dots':
      return (
        <div>
          <Lottie
            options={defaultOptions}
            height={hight}
            width={width}
            isStopped={false}
            isPaused={false}
          />
        </div>
      );
    case 'spinner':
      return (
        <div className="animate-spin">
          <ImSpinner2 size={size} />
        </div>
      );
    default:
      return null;
  }
};

export default Waiting;
