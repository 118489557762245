import api from 'api';
import PositionsList from 'models/careers/positionsList';
import Position from 'models/careers/position';

export const createApplication = data => {
  return async () => {
    const formData = new FormData();

    Object.entries(data).forEach(([key, val]) => {
      if (!val) return;
      if (val instanceof FileList) {
        for (let i = 0; i < val.length; i++) {
          formData.append(key, val[i]);
        }
      } else {
        formData.append(key, val);
      }
    });

    await api(`/careers/applications`, {
      method: 'POST',
      body: formData,
      fileToUpload: true
    });
    return;
  };
};

export const authCandidate = data => {
  return async () => {
    await api(`/careers/auth`, {
      method: 'POST',
      body: data
    });
    return;
  };
};

export const getPositions = () => {
  return async () => {
    const data = await api(`/careers/positions`);
    return new PositionsList(data.positions);
  };
};

export const getLinkedInLink = (id = '') => {
  return async () => {
    const data = await api(`/careers/auth/linkedin/${id}`);
    return data;
  };
};

export const getPosition = id => {
  return async () => {
    const data = await api(`/careers/positions/${id}`);
    return new Position(data);
  };
};

export const emailCheck = ({ email, id }) => {
  return async () => {
    const data = await api(`/careers/candidate/${email}/code?position=${id}`);
    return data;
  };
};

export const getCandidateData = () => {
  return async () => {
    const data = await api(`/careers/candidate`);
    return data;
  };
};

export const forgotCandidate = () => {
  return async () => {
    await api(`/careers/auth/forget`);
    return;
  };
};

export const isAlreadyApplied = id => {
  return async () => {
    const data = await api(`/careers/applications?position=${id}`);
    return data;
  };
};
