import React from 'react';
import PAGE from '../../../components/page';
import { P, H1, UL, LI, STRONG, H4, BR, TAB } from '../../../components';

const Index = () => {
  return (
    <PAGE>
      <H1>Donate Option</H1>
      <H4>Quickly generate a donate link</H4>
      <BR />
      <H4>Link</H4>
      <P>
        It might be more convenient for some donors to donate via a payment link
        rather than via the payment widget on your site. Besides, some
        organisations might have no online presence. In such cases you can use
        MilaCoins to generate payment links and send them via Email or instant
        messengers to the person who is willing to donate money to your cause.
      </P>
      <H4>Button</H4>
      <P>
        Adding donate buttons from MilaCoins to your website is similar to
        adding a standard MilaCoins payment button. This can be done in seconds:
      </P>
      <TAB>
        <UL>
          <LI>
            Specify the cryptocurrency you would like to receive donations in.
          </LI>
          <LI>Customise the colour, shape, and size of the donation button.</LI>
          <LI>
            Copy & paste the code of the MilaCoins widget to your website.
          </LI>
        </UL>
      </TAB>
      <H4>QR Code</H4>
      <P>
        Raising money via QR codes might make the whole process more
        user-friendly. People will be more likely to make crypto contributions
        if they are offered such an easy way of doing this. Choose this option
        and add the MilaCoins QR codes on your website, app or offline creatives
        to increase donations to your charity cause.
      </P>
      <BR />
      <P>
        <STRONG>Your donors will need to do the following:</STRONG>
      </P>
      <UL>
        <LI>open a wallet app that has a balance of the crypto.</LI>
        <LI>enter the amount they are willing to donate.</LI>
        <LI>select the QR option.</LI>
        <LI>scan the MilaCoins QR code to get the address.</LI>
        <LI>verify the amount being sent and confirm the transaction.</LI>
      </UL>
      <BR />
    </PAGE>
  );
};

export default Index;
