import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/logoPub.svg';

const Logo = ({ scrollOnTop }) => {
  return (
    <>
      <Link to="/" className="flex">
        <img
          className={`w-auto transition duration-1000 ease-in-out ${
            scrollOnTop ? 'h-8 sm:h-16' : 'transform scale-75 h-10 sm:h-12'
          }`}
          src={logo}
          alt="logoMila"
        ></img>
      </Link>
    </>
  );
};

export default Logo;
