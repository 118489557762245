import React from 'react';
import PAGE from '../../../components/page';
import { H1, H4, P, BR } from '../../../components';

const Index = () => {
  return (
    <PAGE>
      <H1>Introduction</H1>
      <P>
        <H4>Welcome to MilaCoins!</H4>
      </P>
      <P>
        Our payment platform has been designed to provide a user-friendly, cheap
        and secure gateway to accept cryptocurrency payments. It can be used by
        companies of different sizes for automating the process of accepting
        cryptocurrencies payments from customers and making currency exchange
        transactions.
      </P>
      <P>
        Everything you might need for your business transactions is at your
        disposal. Our payment solutions include instant invoicing, payment
        widgets, crypto-fiat conversion, and more.
      </P>
      <P>
        Juggling multiple projects? With MilaCoins you can create wallets for
        multiple projects and manage them all from a single account without any
        confusion. Can’t do it by yourself? Add as many users as needed with the
        required access level to get the job done.
      </P>
      <P>
        Interested in integrating your website with MilaCoins via API? You’ll
        get the assistance required.
      </P>

      <BR />
    </PAGE>
  );
};

export default Index;
