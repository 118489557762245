import React from 'react';
import { H1, H4, P, UL, LI, STRONG } from '../../../components';
import PAGE from '../../../components/page';
import { SHARPIMAGE } from '../../../components/images';
import invoice from './invoice.png';
import refund from './refund.png';
import table from './table.png';
import info from './info.png';
import email from './email.png';
import underpaid from './underpaid.png';

const Index = () => {
  return (
    <PAGE>
      <H1>Invoicing</H1>
      <H4>COMMON INVOICE ISSUES</H4>
      <UL>
        <LI>Invoice Underpayment </LI>
      </UL>
      <P>
      Underpayment of an invoice occurs when the customer lacks funds to pay the invoice or enters the incorrect amount. In this case, the MilaCoins system automatically notifies the customer that the invoice can’t be paid.
       </P>
      <SHARPIMAGE>{invoice}</SHARPIMAGE>
      <UL>
        <LI>Expired Invoice </LI>
      </UL>
      <P>
      The invoice expires when your customer fails to pay it on time. When the payment address is created, the invoice amount is converted from fiat to crypto, and the rate is locked in for 10 minutes. There will be an 10-minute expiration period, after which a new address will need to be generated.
To issue a refund, visit the “Activity” section and press the “Refund” button. You will see the payment by invoice that can be refunded.

      </P>
      <P>
        To issue a refund, visit the “Activity” section and press the “Refund”
        button. You will see the payment by invoice that can be refunded.
      </P>
      <SHARPIMAGE>{refund}</SHARPIMAGE>

      <UL>
        <LI>Invoice Overpayment (REFUND) </LI>
      </UL>
      <P>
        Invoice overpayment occurs when a customer pays more for the item than
        it actually costs. In this particular case the refund can be requested.
      </P>
      <SHARPIMAGE>{underpaid}</SHARPIMAGE>
      <P>
        Please note that the network fees will be deducted from the refunded
        amount.
      </P>
      <P>
        If your customer overpaid the invoice, you can issue a refund. As a
        platform user, you have final authority over refunds and can initiate
        them with a click of a button. Since blockchain transactions are
        irreversible, customers can’t get their money back without your consent.
        When you make a refund, your customer gets back the paid amount in full
        minus applicable fees (eg. miner fees that depend on the corresponding
        cryptocurrency network).
      </P>
      <P>
        <STRONG>How it works</STRONG>
      </P>
      <P>To issue a refund, go to MilaCoins “Activity” section.</P>
      <SHARPIMAGE>{table}</SHARPIMAGE>
      <P>
        Once you press the “Refund” button, MilaCoins system will automatically
        start processing the refund. When the refund request gets processed,
        your customer will receive a refund notification form via email.
      </P>
      <P>
        Once the refund has been initiated by the system, it looks as follows:
      </P>
      <SHARPIMAGE>{info}</SHARPIMAGE>
      <SHARPIMAGE>{email}</SHARPIMAGE>
    </PAGE>
  );
};

export default Index;
