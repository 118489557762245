import React from 'react';
import { H1, P } from '../../../components';
import PAGE from '../../../components/page';

const Index = () => {
  return (
    <PAGE>
      <H1>Setup</H1>
      <P>
        You can set up your MilaCoins account in minutes. Fill in the details in
        Profile, Company, Wallet, Project, and Security Sections of your account
        settings. Get your account verified by the MilaCoins support and start
        accepting crypto payments with ease.
      </P>
    </PAGE>
  );
};

export default Index;
