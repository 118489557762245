import api from "api";

export const fetchRefund = (token) => {
  return async () => {
    return await api("/invoice/refund", { params: { token } });
  };
};

export const sendRefund = (data, token) => {
  return async () => {
    return await api("/invoice/refund/send", {
      method: "POST",
      body: { address: data.sendAddress, minerFee: data.minerFee },
      params: { token },
    });
  };
};
