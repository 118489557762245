import React from 'react';
import { H1, P } from '../../../../components';
import PAGE from '../../../../components/page';
import { SHARPIMAGE } from '../../../../components/images';
import send from './sendBitcoin.png';

const Index = () => {
  return (
    <PAGE>
      <H1>SEND</H1>
      <P>
        You can send funds via crypto wallets only. To initiate a crypto
        payment, enter the amount of money you want to send (unlimited). You
        will be asked to choose the currency of the transaction and then copy &
        paste the address of the external crypto wallet you wish to send the
        funds to. All wallet types are supported. Before clicking SEND,
        double-check the entered details.{' '}
      </P>
      <SHARPIMAGE>{send}</SHARPIMAGE>
      <P>
        Once you click SEND, your transaction is added to the blockchain. This
        will take some time as the transaction must be authenticated and
        authorized. While the blockchain system validates your transaction, its
        status is marked as “pending” in the “Activity” Section. Once it gets
        processed, the status of this transaction will change to “completed”.{' '}
      </P>
    </PAGE>
  );
};

export default Index;
