import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { FiChevronRight } from 'react-icons/fi';
import { useWindowSize } from 'myHooks';

const Item = ({ url, icon, title, subTitle, badge, items }) => {
  const screenSize = useWindowSize();
  const dispatch = useDispatch();
  const { config } = useSelector(
    state => ({
      config: state.config,
      navigation: state.navigation
    }),
    shallowEqual
  );
  const { collapsed } = { ...config };

  const [hidden, setHidden] = useState(true);
  let location = useLocation();
  let { pathname } = { ...location };
  let active = pathname === url ? true : false;

  useEffect(() => {
    let i = 2;
    const pathnameSplit = pathname.split('/'),
      urlSplit = url.split('/');
    if (!hidden) setHidden(true);
    while (i < pathnameSplit.length && i < urlSplit.length) {
      if (pathnameSplit[i] === urlSplit[i]) setHidden(false);

      i++;
    }
  }, [pathname, url, hidden, setHidden]);

  if (items.length === 0) {
    return (
      <div
        onClick={() =>
          screenSize.width < 768 &&
          dispatch({
            type: 'SET_CONFIG_KEY',
            key: 'collapsed',
            value: !collapsed
          })
        }
      >
        <Link
          to={url}
          className={`left-sidebar-item-doc ${
            subTitle !== undefined ? `my-3` : 'my-1'
          } ${active ? 'text-mila-300' : ''}`}
        >
          <div className="flex space-x-2 items-center">
            {icon}
            <div className="flex flex-col">
              <span className="title text-base capitalize font-semibold">
                {title}
              </span>
              {subTitle && (
                <>
                  <span className="text-grey-500 text-sm">{subTitle}</span>
                </>
              )}
            </div>
          </div>
          {badge && (
            <span className={`badge badge-circle badge-sm ${badge.color}`}>
              {badge.text}
            </span>
          )}
        </Link>
      </div>
    );
  }
  return (
    <button
      className={`left-sidebar-item-doc ${active ? 'text-mila-300' : ''} ${
        hidden ? 'hidden-sibling' : 'open-sibling'
      }`}
    >
      <Link to={url}>
        <div className="flex space-x-2 items-center">
          {icon}

          <span className="title text-base capitalize font-semibold">
            {title}
          </span>

          {badge && (
            <span className={`badge badge-circle badge-sm ${badge.color}`}>
              {badge.text}
            </span>
          )}
          <FiChevronRight
            //onClick={() => setHidden(prev => !prev)}
            className={`ml-auto arrow ${hidden ? '' : 'transform rotate-270'}`}
          />
        </div>
      </Link>
    </button>
  );
};

export default Item;
