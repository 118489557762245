export const REDIRECT = 'redirect',
  RETURN_MESSAGE = 'message',
  THROW = 'throw',
  httpStatus = {
    403: { action: REDIRECT, path: '/login' },
    413: { action: RETURN_MESSAGE, message: 'The file is Too Large' },
    500: { action: REDIRECT, path: '/error-page500' }
  },
  errorList = {
    ECONNABORTED: { action: REDIRECT, path: '/error-timeout', logIt: true },
    500: { action: REDIRECT, path: '/error-page500' },
    604: { action: REDIRECT, path: '/error-page500' },
    600: { action: REDIRECT, path: '/login' },
    601: { action: REDIRECT, path: '/error-token-expired' },
    602: { action: REDIRECT, path: '/login' },
    603: { action: REDIRECT, path: '/login' },
    609: { action: RETURN_MESSAGE },
    400: { action: RETURN_MESSAGE },
    401: { action: RETURN_MESSAGE },
    102: { action: RETURN_MESSAGE },
    103: { action: RETURN_MESSAGE },
    110: { action: RETURN_MESSAGE },
    114: { action: RETURN_MESSAGE },
    115: { action: RETURN_MESSAGE },
    116: { action: RETURN_MESSAGE },
    117: { action: RETURN_MESSAGE, message: 'Your amount is not valid.' },
    118: { action: RETURN_MESSAGE },
    119: { action: RETURN_MESSAGE },
    120: { action: RETURN_MESSAGE },
    121: {
      action: RETURN_MESSAGE,
      message: 'Your amount is lower then minimum amount.'
    },

    126: { action: RETURN_MESSAGE },
    132: { action: RETURN_MESSAGE },
    133: { action: RETURN_MESSAGE },
    134: { action: RETURN_MESSAGE },
    135: { action: RETURN_MESSAGE },
    136: { action: RETURN_MESSAGE },
    137: { action: RETURN_MESSAGE },
    144: {
      action: RETURN_MESSAGE,
      message: 'This Email Is Already Use By Another User.'
    },
    138: { action: RETURN_MESSAGE },
    139: { action: RETURN_MESSAGE },
    140: { action: RETURN_MESSAGE },
    146: {
      action: RETURN_MESSAGE,
      message: 'No Project Has Been Assigned To This User.'
    },
    150: {
      action: RETURN_MESSAGE,
      message: 'You Have To Provide At Least One Project.'
    },
    152: { action: RETURN_MESSAGE },
    153: { action: RETURN_MESSAGE },
    183: { action: RETURN_MESSAGE },
    104: { action: RETURN_MESSAGE },
    171: { action: RETURN_MESSAGE },
    671: { action: RETURN_MESSAGE },
    706: {
      action: RETURN_MESSAGE
      //message: 'There is a problem with the server request.'
    },
    608: { action: RETURN_MESSAGE },
    620: { action: RETURN_MESSAGE, message: 'To many requests.' },
    610: { action: THROW },
    611: { action: THROW },
    612: { action: THROW },
    613: { action: THROW },
    614: { action: THROW },
    615: { action: THROW },
    616: { action: THROW },
    157: { action: THROW },
    158: { action: THROW },
    159: { action: THROW },
    160: { action: THROW },
    161: { action: THROW },
    11000: {
      action: RETURN_MESSAGE
    },
    41202: {
      action: RETURN_MESSAGE,
      message: 'Its look like you are already applied for this job.'
    },
    44301: {
      action: RETURN_MESSAGE
    },
    44302: {
      action: RETURN_MESSAGE
    }
  };
