import React from 'react';
import { MdNavigateNext } from 'react-icons/md';
import { Link } from 'react-router-dom';

const Index = ({ title, text, img, link }) => {
  return (
    <Link to={link}>
      <div className="transition parent h-full bg-white duration-500 ease-in-out border-2 border-grey-300 hover:shadow-lg rounded-lg">
        <div className="bg-mila-750 parent-hover:bg-mila-751 rounded-t-lg transition duration-500 ease-in-out w-full flex justify-center items-center">
          {img}
        </div>
        <div className="h-5 bg-mila-750 parent-hover:bg-mila-751 transition duration-500 ease-in-out docCard-bottom"></div>
        <div className="pl-5 pt-8">
          <h4 className="hpBoldFont leading-none">{title}</h4>
        </div>
        <div className="flex flex-col py-8 items-center">
          <p className="w-10/12 text-grey-700 text-lg hpRegFont normal-case leading-1">
            {text}
          </p>
          <div className="flex py-8 justify-center">
            <span className="w-11/22">
              <button className="btn p-2 btn-default bg-mila-400 hover:bg-mila-700 text-white border-mila-400 btn-rounded">
                <span className="text-base flex space-x-2">
                  <span className="flex justify-center lg:hidden xl:flex">
                    Learn More
                  </span>
                  <span className="hidden justify-center lg:flex xl:hidden">
                    Learn More
                  </span>
                  <MdNavigateNext />
                </span>
              </button>
            </span>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default Index;
