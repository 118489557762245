import { useSelector } from 'react-redux';
import deepEqual from 'deep-equal';

const useMySelector = fnSelector => {
  const selector = useSelector(fnSelector, (current, prev) => {
    return deepEqual(current, prev);
  });

  return selector;
};

export default useMySelector;
