import React from 'react'
import {FiX} from 'react-icons/fi'

export const ModalHeader = ({onClick,title}) => (
  <div className="modal-header">
    <h3 className="text-xl font-semibold">{title}</h3>
    <button className="modal-close btn btn-transparent" onClick={onClick}>
      <FiX size={18} className="stroke-current" />
    </button>
  </div>
)
