import axios from 'axios';
let isReady = false;
try {
  const scriptElement = document.createElement('script');

  scriptElement.src =
    'https://www.google.com/recaptcha/api.js?render=6LdAGc0cAAAAANufX8gnn0m754v4jY-w3EDsMnPe';

  const handleLoad = () => {
    window.grecaptcha.ready(function() {
      isReady = true;
    });
  };

  const handleError = error => {
    axios
      .post(`${process.env.REACT_APP_API}/logs/unknownError`, { ...error })
      .then(function(response) {
        console.log(response.data);
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  scriptElement.addEventListener('load', handleLoad);
  scriptElement.addEventListener('error', handleError);
  document.head.appendChild(scriptElement);
} catch (err) {
  axios
    .post(`${process.env.REACT_APP_API}/logs/unknownError`, { ...err })
    .then(function(response) {
      console.log(response.data);
    })
    .catch(function(error) {
      console.log(error);
    });
}

export default function() {
  return new Promise(resolve => {
    if (!isReady) return resolve(null);

    window.grecaptcha
      .execute('6LdAGc0cAAAAANufX8gnn0m754v4jY-w3EDsMnPe')
      .then(function(token) {
        resolve(token);
      })
      .catch(err => resolve(null));
  });
}
