import React from 'react';
import { Link } from 'react-router-dom';
import { navBar } from './navBarLinks';
import { GrClose } from 'react-icons/gr';

const Index = ({ setIsSideOpen }) => {
  return (
    <div
      onClick={() => setIsSideOpen(false)}
      className="h-screen fixed w-full bg-blackTrans z-40"
    >
      <div className="bg-white flex flex-col shadow w-2/3 max-w-6xl h-screen fixed top-0 right-0 z-50 pt-10">
        <span
          className="border-b cursor-pointer w-full border-grey-100 pl-10 py-3"
          onClick={() => setIsSideOpen(false)}
        >
          <GrClose size={22} />
        </span>
        <Link
          className="border-b hover:bg-grey-100 border-grey-100 pl-10 py-3 w-full"
          to="/"
        >
          <span
            className="hpRegFont text-lg"
            onClick={() => setIsSideOpen(false)}
          >
            Homepage
          </span>
        </Link>
        <Link
          className="border-b bg-grey-100 border-grey-100 pl-10 py-3 w-full"
          to="/login"
        >
          <span
            className="hpBoldFont text-mila-300 text-xl"
            onClick={() => setIsSideOpen(false)}
          >
            Login
          </span>
        </Link>
        {navBar.map((item, key) => {
          return (
            item.title !== 'Sign Up' &&
            item.title !== 'Login' && (
              <Link
                className="border-b hover:bg-grey-100 border-grey-100 pl-10 py-3 w-full"
                key={key}
                to={item.link}
              >
                <span
                  className="hpRegFont text-lg"
                  onClick={() => setIsSideOpen(false)}
                >
                  {item.title}
                </span>
              </Link>
            )
          );
        })}
      </div>
    </div>
  );
};

export default Index;
