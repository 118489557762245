import React, { useEffect, useState } from 'react';
import { useLocationByIP } from 'myHooks';
import Modal from 'components/modals';
import countries from 'json/countriesList.json';

const NotAllowedCountry = ({ className = '' }) => {
  const [visible, setVisible] = useState(false);
  const { countryCode = null } = useLocationByIP();

  useEffect(() => {
    if (countries[countryCode] && countries[countryCode].allowed === false)
      setVisible(true);
  }, [countryCode]);

  const name = countries[countryCode]
    ? countries[countryCode].name
    : 'your country';
  return (
    <Modal
      visible={visible}
      setVisible={setVisible}
      title={''}
      bodyRender={
        <div
          className={`w-full border items-center flex flex-row space-x-4 rounded relative bg-red-100 text-red-600 py-8 px-4`}
        >
          <strong className="font-bold text-lg">
            <p className="break-normal">
              Currently, we do not operate in {name}.<br />
              <a href="https://www.milacoins.com/waiting-list">
                <span className="underline">
                  Join the waiting list for updates.
                </span>
              </a>
            </p>
          </strong>
        </div>
      }
    />
  );
};

export default NotAllowedCountry;
